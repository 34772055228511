export type IAvailability = {
  [key in AvailabilityKey]: IAvailabilityDay[];
};

export type AvailabilityKey = keyof typeof WEEK_DAY;

export interface IAvailabilityDay {
  start: string | null;
  end: string | null;
}

export const DEFAULT_AVAILABILITY: IAvailability = {
  sun: [],
  mon: [{ start: null, end: null }],
  tue: [{ start: null, end: null }],
  wed: [{ start: null, end: null }],
  thu: [{ start: null, end: null }],
  fri: [{ start: null, end: null }],
  sat: [],
};

export enum WEEK_DAY {
  sun = "Sunday",
  mon = "Monday",
  tue = "Tuesday",
  wed = "Wednesday",
  thu = "Thursday",
  fri = "Friday",
  sat = "Saturday",
}
