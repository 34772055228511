import React, {
  InputHTMLAttributes,
  useEffect,
  useMemo,
  useState,
} from "react";

interface RadioGroupProps extends InputHTMLAttributes<HTMLInputElement> {
  data: string[];
}

export const RadioGroup: React.FC<RadioGroupProps> = ({ data, ...rest }) => {
  const [selected, setSelected] = useState(data[0]);

  useEffect(() => {
    setSelected(data[0]);
  }, [data]);

  return (
    <div className="flex items-center gap-x-[10px] gap-y-[10px] flex-1 ">
      {data.map((item) => (
        <Radio
          key={item}
          {...rest}
          value={item}
          checked={selected === item}
          onChange={() => setSelected(item)}
        />
      ))}
    </div>
  );
};

const Radio: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({
  ...rest
}) => {
  const checkedStyle = useMemo(
    () =>
      rest.checked
        ? "bg-primary-default text-white"
        : "border border-solid border-gray-default text-gray-default",
    [rest.checked]
  );

  return (
    <label
      className={`rounded-full flex items-center gap-[8px] font-termina h-[34px] text-[14px] leading-7 px-[12px] py-[6px] cursor-pointer whitespace-nowrap ${checkedStyle}`}
    >
      <input type="radio" {...rest} className="hidden" />
      <div
        className={`border border-solid  p-[3px] rounded-full w-[18px] h-[18px] ${
          rest.checked ? "border-white" : "border-gray-default"
        }`}
      >
        <span
          className={`block w-full h-full rounded-full ${
            rest.checked && "bg-white"
          }`}
        />
      </div>
      {rest.value}
    </label>
  );
};
