import { useCallback, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../domain/ducks/authReducer";
import {
  IAvailability,
  IAvailabilityDay,
  DEFAULT_AVAILABILITY,
} from "../../domain/entities/Availability";
import { AppDispatch } from "../../domain/DomainLayer";
import { patchAvailabilityThunk } from "../../domain/thunks/authThunk";

export type UseAvailabilityProps = {
  availability: IAvailability;
  changeAvailability: (info: { [key: string]: IAvailabilityDay[] }) => void;
  actions: { handleUpdateAvailability: () => void };
};

export function useAvailability(): UseAvailabilityProps {
  const { user } = useSelector(authSelector);

  const dispatch = useDispatch<AppDispatch>();

  const reducer = (
    availability: IAvailability,
    info: { [key: string]: IAvailabilityDay[] }
  ): IAvailability => ({
    ...availability,
    ...info,
  });
  const [availability, changeAvailability] = useReducer(reducer, {
    ...DEFAULT_AVAILABILITY,
    ...user?.availability,
  });

  const handleUpdateAvailability = useCallback(async () => {
    dispatch(patchAvailabilityThunk(availability));
  }, [availability, dispatch]);

  return {
    availability,
    changeAvailability,
    actions: {
      handleUpdateAvailability,
    },
  };
}
