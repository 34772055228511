import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Aside } from "../components/organisms/Aside";
import { ChatMessage } from "../components/atoms/ChatMessage";
import { useChatbot } from "../hooks/useChatbot";

import { Input } from "antd";

import { ReactComponent as ArrowIcon } from "../assets/img/icons/arrow.svg";

export const Chatbot: React.FC = () => {
  const chatRef = useRef<HTMLDivElement>(null);
  const [chatMessage, setChatMessage] = useState("");

  const {
    loading,
    chat,
    actions: { handleSendMessage },
  } = useChatbot();

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, []);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const message = chatMessage;

      setChatMessage("");

      handleSendMessage(message);
    },
    [handleSendMessage, chatMessage]
  );

  return (
    <section className="flex gap-[33px] h-full">
      <Aside>
        <div className="__blank" />
        <h1 className="text-gray-light font-urbanist text-[22px] leading-[46px]">
          Chatbot
        </h1>
        <p className="text-gray-extra font-roboto text-[18px] font-normal leading-[22px]">
          With its expertise, it assists users in resolving doubts and finding
          solutions to drive business expansion. From market insights to
          tailored strategies, our chatbot empowers users to make informed
          decisions for their growth journey. Experience the power of AI and
          unlock your business's full potential with our strategic growth
          chatbot.
        </p>
      </Aside>
      <div
        ref={chatRef}
        className="flex-1 flex flex-col p-[32px] pl-0 gap-[32px] overflow-y-auto"
      >
        <section className="flex-1 flex flex-col justify-end gap-[15px]">
          {chat.length > 0 &&
            chat.map((item, index) => (
              <ChatMessage
                key={index}
                name="Chatbot"
                side={item.origin === "user" ? "right" : "left"}
                className={`${
                  item.origin === "user"
                    ? "bg-white rounded-full py-[6px] pr-[8px] pl-[16px]"
                    : "px-[8px]"
                }`}
              >
                {item.message}
              </ChatMessage>
            ))}

          {loading && (
            <ChatMessage name="Chatbot" side="left">
              <span className="loading">
                <span />
                <span />
                <span />
              </span>
            </ChatMessage>
          )}
        </section>
        <form onSubmit={handleSubmit} className="flex gap-[13px]">
          <Input
            type="text"
            name="message"
            placeholder="Type your message here..."
            className="rounded-full py-[8px] px-[16px] h-[68px] border-none placeholder:text-[#9C9C9C] font-roboto text-base font-normal leading-6"
            value={chatMessage}
            onChange={(e) => setChatMessage(e.target.value)}
          />
          <button
            type="submit"
            className="w-[78px] h-[68px] bg-primary-default rounded-full flex  items-center justify-center"
          >
            <ArrowIcon fill="#fff" />
          </button>
        </form>
      </div>
    </section>
  );
};
