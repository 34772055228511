import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Calendar, Modal, Skeleton, Spin } from "antd";

import { ReactComponent as CalendarCircleIcon } from "../assets/img/icons/calendar-circle.svg";
import { ReactComponent as ArrowLinedIcon } from "../assets/img/icons/arrow-lined.svg";

import dayjs from "dayjs";
import dayLocaleData from "dayjs/plugin/localeData";
import isToday from "dayjs/plugin/isToday";
import isBetween from "dayjs/plugin/isBetween";

import { useNavigate, useParams } from "react-router";
import { useSchedule } from "../hooks/useSchedule";
import { ROUTES } from "../Router";
import { Aside } from "../components/organisms/Aside";
import { Button } from "../components/atoms/Button";
import { CellRender } from "../components/atoms/CellRender";

import styles from "../styles/_schedule.module.scss";
import { useSelector } from "react-redux";
import { surveySelector } from "../../domain/ducks/surveyReducer";
import { Breadcrumbs } from "../components/atoms/Breadcrumb";
import { AvailabilityKey } from "../../domain/entities/Availability";
import { LOADING_DUMP } from "../utils/constants";

dayjs.extend(dayLocaleData);
dayjs.extend(isToday);
dayjs.extend(isBetween);

export const Schedule: React.FC = () => {
  const { email, companyName, availability } = useSelector(surveySelector);
  const { scheduleId } = useParams();

  const [confirmModal, setConfirmModal] = useState(false);

  const [loadingTimes, setLoadingTimes] = useState(false);

  const navigate = useNavigate();

  if (!scheduleId) {
    navigate(ROUTES.LOGIN);
  }

  const {
    loading,
    schedule,
    changeSchedule,
    times,
    actions: { handleSubmitSchedule, handleGetTimes, handleCheckLink },
  } = useSchedule(scheduleId as string);

  useEffect(() => {
    handleCheckLink();
    handleGetTimes();
  }, [handleGetTimes, handleCheckLink]);

  const handleConfirmSubmit = useCallback(() => {
    handleSubmitSchedule();
    navigate(ROUTES.LOGIN);
    setConfirmModal(false);
  }, [handleSubmitSchedule, navigate]);

  const availableTimes = useMemo(() => {
    setLoadingTimes(true);
    const available: string[] = [];

    const weekDay = dayjs(schedule.date)
      .format("ddd")
      .toLocaleLowerCase() as AvailabilityKey;

    if (availability) {
      availability[weekDay]?.forEach(({ start, end }) => {
        times.forEach((time) => {
          if (
            dayjs(`${schedule.date} ${time}`).isBetween(
              `${schedule.date} ${start}`,
              `${schedule.date} ${end}`
            ) ||
            dayjs(`${schedule.date} ${time}`).isSame(
              `${schedule.date} ${start}`
            ) ||
            dayjs(`${schedule.date} ${time}`).isSame(`${schedule.date} ${end}`)
          ) {
            available.push(time);
          }
        });
      });
    }

    setTimeout(() => setLoadingTimes(false), 3000);

    return available;
  }, [times, schedule.date, availability]);

  if (loading) {
    return <Spin />;
  }

  return (
    <div className={`${styles.schedule} flex h-full`}>
      <Aside title={email as string} subtitle={companyName as string}>
        <div className="__blank" />
      </Aside>

      <div className="flex flex-col flex-1 gap-[30px] py-[22px] px-[30px]">
        <Breadcrumbs
          items={[
            { title: "Registration Company" },
            { title: "Form" },
            { title: "Schedule" },
          ]}
        />
        <div className="rounded-[10px] shadow bg-white p-[20px] flex-1 flex flex-col gap-[12px]">
          <p>⚡ Choose a day and time that best suits our mentors</p>

          <div className="bg-white flex flex-1 rounded-[20px] gap-[21px]">
            <div className="flex flex-col shadow rounded-[20px] px-[23px] py-[90px] max-w-[660px]">
              <Calendar
                fullscreen={false}
                disabledDate={(day) => day.isBefore(dayjs()) && !day.isToday()}
                onSelect={(day) =>
                  changeSchedule({
                    date: day.format("YYYY-MM-DD"),
                  })
                }
                fullCellRender={(day) => (
                  <CellRender
                    day={day}
                    selected={schedule.date === day.format("YYYY-MM-DD")}
                  />
                )}
                headerRender={({ value, type, onChange, onTypeChange }) => {
                  let current = value.clone();
                  const localeData = value.localeData();
                  const months = [];
                  for (let i = 0; i < 12; i++) {
                    current = current.month(i);
                    months.push(localeData.months(current));
                  }

                  const year = value.year();
                  const month = value.month();

                  function monthNavigation(direction: number) {
                    onTypeChange("month");
                    const newValue = value.clone().add(direction, "month");
                    onChange(newValue);
                  }

                  return (
                    <div className="flex justify-center items-center gap-4 px-4 pb-[35px]">
                      <Button
                        className="!w-[40px] flex align-center justify-center rotate-180 shadow"
                        variant="secondary"
                        disabled={value.isBefore(dayjs())}
                        onClick={() => monthNavigation(-1)}
                      >
                        <ArrowLinedIcon />
                      </Button>
                      <span className="w-[200px] text-center text-[#333] font-termina text-xl font-normal leading-6 capitalize">
                        {months[month]} {year}
                      </span>
                      <Button
                        className="!w-[40px] flex align-center justify-center shadow"
                        variant="secondary"
                        onClick={() => monthNavigation(1)}
                      >
                        <ArrowLinedIcon />
                      </Button>
                    </div>
                  );
                }}
              />
            </div>
            {schedule.date && (
              <div className="flex flex-col shadow rounded-[20px] px-[23px] py-[90px]  flex-1">
                <div className="w-[312px] flex flex-col items-center flex-1">
                  <p className="text-center text-black font-termina text-base font-normal leading-5 whitespace-nowrap">
                    {dayjs(schedule.date).format("ddd, MMMM D")}
                  </p>
                  {loadingTimes ? (
                    <div className="flex flex-col items-center gap-[10px] mt-[45px] w-full">
                      {LOADING_DUMP.map((_, index) => (
                        <Skeleton
                          active
                          key={index}
                          title={{
                            className:
                              "!h-[50px] !w-[150px] mx-auto !rounded-full",
                          }}
                          paragraph={false}
                        />
                      ))}
                    </div>
                  ) : availableTimes.length > 0 ? (
                    <div className="hide-scroll flex flex-col items-center gap-[10px] mt-[45px] w-full flex-1 max-h-[420px] overflow-y-auto">
                      {availableTimes.map((time) => (
                        <Button
                          key={time}
                          variant={
                            schedule.time === time ? "tertiary" : "secondary"
                          }
                          onClick={() => changeSchedule({ time })}
                          className="text-gray-dark font-roboto !font-[400] !text-[18px] leading-5 w-[150px]"
                        >
                          {time}
                        </Button>
                      ))}
                    </div>
                  ) : (
                    <div className="flex-1">
                      <span className="block font-termina mt-[50px]">
                        No available times on{" "}
                        {dayjs(schedule.date).format("ddd")}
                      </span>
                    </div>
                  )}
                  <Button
                    className="mt-[27px] w-[184px]"
                    disabled={!schedule.time}
                    onClick={() => setConfirmModal(true)}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            )}
          </div>

          <Modal
            open={confirmModal}
            closable={false}
            footer={false}
            centered
            classNames={{ content: "!rounded-[20px] w-[570px] !p-0" }}
          >
            <div className="flex flex-col items-center gap-[56px] h-fit p-[20px]">
              <CalendarCircleIcon className="text-primary-default" />
              <p className="text-gray-dark text-center font-termina text-[16px] font-[600] leading-5">
                Your meeting details are as follows:
              </p>
              <h3 className="text-gray-darker text-center font-termina text-[28px] font-[400] leading-[164.286%]">
                {dayjs(`${schedule.date} ${schedule.time}`).format(
                  "MMMM DD, YYYY [at] h:mm A"
                )}
              </h3>
              <span className="text-gray-dark text-center font-roboto text-[12px] leading-6">
                Thanks for scheduling! Looking forward to our meeting.
                <br />
                Adjustments to the schedule can be made as needed.
              </span>
              <div className="flex gap-[47px]">
                <Button
                  variant="secondary"
                  onClick={() => setConfirmModal(false)}
                >
                  Cancel
                </Button>
                <Button onClick={handleConfirmSubmit}> Confirm</Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};
