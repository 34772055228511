import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { initializePresentationLayer } from "./presentation/PresentationLayer";
import { persistor, store } from "./domain/DomainLayer";
import { initializeDataLayer } from "./data/DataLayer";

import "./index.scss";

initializeDataLayer(store);
const App = initializePresentationLayer(store, persistor);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <App />
);

reportWebVitals();
