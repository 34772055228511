import { Dispatch } from "redux";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { EngageState } from "../DomainLayer";
import {
  changeStatusSurvey,
  getSurveyById,
  getSurveys,
  saveSurvey,
} from "../../data/services/survey";
import { ISurvey } from "../entities/Survey";
import { IAnswer } from "../entities/SurveyAnswers";
import { toast } from "react-toastify";

export interface ThunkApi {
  dispatch: Dispatch;
  state: EngageState;
  rejectValue: string;
}

export const ScheduleActions = {
  GET_SURVEYS: "thunk/survey/getSurveys",
  GET_SURVEY_BY_ID: "thunk/survey/getSurvey",
  SAVE_SURVEY: "thunk/survey/saveSurvey",
  POST_CHANGE_STATUS: "thunk/survey/postChangeStatus",
};

export const getSurveysThunk = createAsyncThunk<ISurvey[], void, ThunkApi>(
  ScheduleActions.GET_SURVEYS,
  async (payload, thunkAPI) => {
    try {
      return await getSurveys();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getSurveyByIdThunk = createAsyncThunk<ISurvey, string, ThunkApi>(
  ScheduleActions.GET_SURVEY_BY_ID,
  async (payload, thunkAPI) => {
    try {
      return await getSurveyById(payload);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const saveSurveyThunk = createAsyncThunk<void, IAnswer[], ThunkApi>(
  ScheduleActions.SAVE_SURVEY,
  async (payload, thunkAPI) => {
    try {
      const { email, companyName } = thunkAPI.getState().survey;
      await saveSurvey({
        email: email as string,
        companyName: companyName as string,
        answers: payload,
      });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const changeStatusSurveyThunk = createAsyncThunk<
  void,
  { surveyId: string; status: "approved" | "rejected" },
  ThunkApi
>(ScheduleActions.POST_CHANGE_STATUS, async (payload, thunkAPI) => {
  try {
    const availability = thunkAPI.getState().auth.user?.availability;

    if (!availability) {
      toast("Please, check your availability before submitting", {
        type: "error",
      });
      return;
    }

    await changeStatusSurvey(payload.surveyId, payload.status);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});
