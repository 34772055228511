import { useDispatch, useSelector } from "react-redux";
import { scheduleSelector } from "../../domain/ducks/scheduleReducer";
import { useCallback, useReducer, useState } from "react";
import { ISchedule } from "../../domain/entities/Schedule";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { AppDispatch } from "../../domain/DomainLayer";
import {
  checkScheduleLinkThunk,
  saveScheduleThunk,
} from "../../domain/thunks/scheduleThunk";
import { getTimes } from "../utils/helpers";

dayjs.extend(utc);

type UseScheduleProps = {
  loading: boolean;
  schedule: ISchedule;
  changeSchedule: (info: ISchedule) => void;
  times: string[];
  actions: {
    handleSubmitSchedule: () => void;
    handleGetTimes: () => void;
    handleCheckLink: () => void;
  };
};

const DEFAULT_SCHEDULE: ISchedule = {
  date: "",
  time: "",
};

const START_AVAILABLE_TIME = "00:00";
const END_AVAILABLE_TIME = "23:59";

export function useSchedule(scheduleId: string): UseScheduleProps {
  const { isLoading } = useSelector(scheduleSelector);

  const dispatch = useDispatch<AppDispatch>();

  const [times, setTimes] = useState<string[]>([]);

  const scheduleReducer = (
    schedule: ISchedule,
    info: ISchedule
  ): ISchedule => ({
    ...schedule,
    ...info,
  });

  const [schedule, changeSchedule] = useReducer(scheduleReducer, {
    ...DEFAULT_SCHEDULE,
  });

  const handleGetTimes = useCallback(async () => {
    setTimes(
      getTimes({ start: START_AVAILABLE_TIME, end: END_AVAILABLE_TIME })
    );
  }, []);

  const handleSubmitSchedule = useCallback(() => {
    const datetime = dayjs(`${schedule.date} ${schedule.time}`)
      .utc(false)
      .toISOString();

    dispatch(saveScheduleThunk({ scheduleId, date: datetime }));
  }, [dispatch, schedule, scheduleId]);

  const handleCheckLink = useCallback(() => {
    dispatch(checkScheduleLinkThunk(scheduleId));
  }, [scheduleId, dispatch]);

  return {
    loading: isLoading,
    schedule,
    changeSchedule,
    times,
    actions: {
      handleSubmitSchedule,
      handleGetTimes,
      handleCheckLink,
    },
  };
}
