import { Select } from "antd";
import React, { useCallback, useMemo } from "react";
import { getTimes } from "../../utils/helpers";

import { FaArrowRight } from "react-icons/fa";
import { IAvailabilityDay } from "../../../domain/entities/Availability";

interface TimeRangePickerProps {
  value: IAvailabilityDay;
  onChange?: (pickerValue: IAvailabilityDay) => void;
}

export const TimePickerRange: React.FC<TimeRangePickerProps> = ({
  value,
  onChange,
}) => {
  const times = useMemo(() => getTimes({ start: "00:00", end: "23:59" }), []);

  const handleCheckTime = useCallback((pickerValue: IAvailabilityDay) => {
    const prevTime = pickerValue;

    let currentTime: IAvailabilityDay = { start: null, end: null };

    if (!prevTime.start || !prevTime.end) {
      return pickerValue;
    }

    if (prevTime.start > prevTime.end) {
      currentTime.end = prevTime.start;
      currentTime.start = prevTime.end;
    } else {
      currentTime = pickerValue;
    }

    return currentTime;
  }, []);

  const handleChange = useCallback(
    (position: "start" | "end", pickerValue: string) => {
      const currentTime = handleCheckTime({
        ...value,
        [position]: pickerValue,
      });
      onChange && onChange(currentTime);
    },
    [onChange, value, handleCheckTime]
  );

  return (
    <div className="flex items-center flex-1 gap-[10px] border rounded-[8px]">
      <Picker
        value={value.start}
        options={times}
        onChange={(pickerValue) => handleChange("start", pickerValue)}
      />
      <FaArrowRight className="opacity-30" />
      <Picker
        value={value.end}
        options={times}
        onChange={(pickerValue) => handleChange("end", pickerValue)}
      />
    </div>
  );
};

interface PickerProps {
  value: string | null;
  options: string[];
  onChange: (pickerValue: string) => void;
}

const Picker: React.FC<PickerProps> = ({ value, options, onChange }) => {
  return (
    <Select
      className="flex-1"
      variant="borderless"
      value={value}
      rootClassName="font-termina"
      onChange={(pickerValue) => onChange(pickerValue)}
    >
      {options.map((time) => (
        <Select.Option key={time} value={time}>
          {time}
        </Select.Option>
      ))}
    </Select>
  );
};
