import React, { useCallback, useEffect, useState } from "react";

import ReactFlow, {
  Background,
  Connection,
  Controls,
  Edge,
  Node,
  ReactFlowProvider,
  addEdge,
  getOutgoers,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";

import "reactflow/dist/style.css";
import { Button, Form, Tooltip } from "antd";
import { Breadcrumbs } from "../../components/atoms/Breadcrumb";
import { ROUTES } from "../../Router";
import { NodeGraph } from "../../components/molecules/Graph/NodeGraph";
import { useQuestions } from "../../hooks/useQuestions";
import { FaPlus } from "react-icons/fa";
import { MdRefresh } from "react-icons/md";

import { ModalNode } from "../../components/molecules/Graph/ModalNode";
import { ModalEdge } from "../../components/molecules/Graph/ModalEdge";

import Elk from "elkjs";
import { IQuestion } from "../../../domain/entities/Question";

const elk = new Elk();

const elkOptions = {
  "elk.algorithm": "layered",
  "elk.layered.spacing.nodeNodeBetweenLayers": "0",
  "elk.spacing.nodeNode": "50",
  "elk.spacing.edgeNode": "50",
  "elk.direction": "RIGHT",
  "nodePlacement.strategy": "INTERACTIVE",
};

const mountGraph = (nodes: any, edges: any, options = {}) => {
  const graph = {
    id: "root",
    layoutOptions: options,
    children: nodes.map((node: any) => ({
      ...node,
      targetPosition: "left",
      sourcePosition: "right",

      width: node.width || 250,
      height: node.height || 50,
    })),
    edges: edges,
  };

  return elk
    .layout(graph)
    .then((layoutedGraph) => ({
      nodes: layoutedGraph.children?.map((node) => ({
        ...node,
        position: { x: node.x, y: node.y },
      })),

      edges: layoutedGraph.edges,
    }))
    .catch(console.error);
};

const nodesTypes = { node: NodeGraph };

export const Graph = () => {
  const {
    questions,
    actions: {
      handleGetQuestions,
      handleSaveQuestion,
      handleDeleteQuestion,
      handleConnectQuestion,
      handleDeleteConnectQuestion,
    },
  } = useQuestions();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [currentQuestions, setCurrentQuestions] = useState<IQuestion[]>([]);

  const { getNodes, getEdges, fitView } = useReactFlow();

  const [nodeForm] = Form.useForm();
  const [edgeForm] = Form.useForm();

  const [nodeModal, setNodeModal] = React.useState<boolean>(false);
  const [edgeModal, setEdgeModal] = React.useState<boolean>(false);

  useEffect(() => {
    handleGetQuestions();
  }, [handleGetQuestions]);

  const onConnect = useCallback(
    (params: any) => {
      const selectedQuestion = questions.find(
        (question) => question.id === params.source
      );

      if (selectedQuestion?.type !== "text") {
        edgeForm.setFieldsValue({
          source: params.source,
          target: params.target,
          choices: selectedQuestion?.choices
            ? selectedQuestion?.choices
            : ["Yes", "No"],
        });
        setEdgeModal(true);

        return;
      }

      const savedEdgeId = handleConnectQuestion({
        source: params.source,
        target: params.target,
        choice: null,
      });
      setEdges((eds) => addEdge({ ...params, id: savedEdgeId }, eds));
    },
    [setEdges, edgeForm, questions, handleConnectQuestion]
  );

  const handleMountGraph = async (questions: IQuestion[]) => {
    const refNodes: any[] = [];
    const refEdges: any[] = [];

    questions.forEach((value: any) => {
      refNodes.push({
        id: value.id,
        type: "node",
        data: { label: value.question },
        width: 150,
      });

      if (value.refId) {
        value.refId.forEach((ref: any) => {
          refEdges.push({
            id: `${value.id}-${ref.id}`,
            source: value.id,
            target: ref.id,
            label: ref.choice,
          });
        });
      }
    });

    mountGraph(refNodes, refEdges, elkOptions).then(
      ({ nodes: layoutedNodes, edges: layoutedEdges }: any) => {
        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
      }
    );
  };

  const isValidConnection = useCallback(
    (connection: Connection) => {
      const nodes = getNodes();
      const edges = getEdges();
      const target = nodes.find((node) => node.id === connection.target);
      const source = nodes.find((node) => node.id === connection.source);

      const hasCycle = (node: Node, visited = new Set()) => {
        if (visited.has(node.id)) return false;

        visited.add(node.id);

        for (const outgoer of getOutgoers(node, nodes, edges)) {
          if (outgoer.id === connection.source) return true;
          if (hasCycle(outgoer, visited)) return true;
        }
      };

      const checkConnection = (source: Node, target: Node) => {
        const currentEdge = edges.find(
          (edge) => edge.source === source.id && edge.target === target.id
        );

        return !!currentEdge;
      };

      if (target && target.id === connection.source) return false;
      return (
        !hasCycle(target as Node) &&
        !checkConnection(source as Node, target as Node)
      );
    },
    [getNodes, getEdges]
  );

  useEffect(() => {
    if (questions && questions.length > 0) {
      setCurrentQuestions(
        questions.map((question) => ({
          ...question,
          choices: question.choices
            ? JSON.parse(question.choices as string)
            : null,
        }))
      );
      handleMountGraph(questions);
      fitView();
    } else {
      setNodes([]);
      setEdges([]);
    }
  }, [questions]);

  const handleSubmitNode = useCallback(
    async (values: any) => {
      const savedNodeId = await handleSaveQuestion(values);

      setNodes((prev) => {
        const copyNodes = prev.slice();
        const currentNodeIndex = copyNodes.findIndex(
          (node) => node.id === values.id
        );
        if (currentNodeIndex !== -1) {
          copyNodes.splice(currentNodeIndex, 1, {
            id: values.id,
            type: "node",
            position: copyNodes[currentNodeIndex].position,
            data: { label: values.question },
          });
        } else {
          copyNodes.push({
            id: savedNodeId,
            type: "node",
            position: { x: 0, y: 0 },
            data: { label: values.question },
          });
        }

        return copyNodes;
      });

      setCurrentQuestions((prev) => {
        const copyNodes = prev.slice();
        const currentNodeIndex = copyNodes.findIndex(
          (node) => node.id === values.id
        );
        if (currentNodeIndex !== -1) {
          copyNodes.splice(currentNodeIndex, 1, {
            id: values.id,
            type: values.type,
            category: values.category,
            choices: values.choices,
            isFirst: values.isFirst,
            question: values.question,
            refId: values.refId,
          });
        } else {
          copyNodes.push({
            id: savedNodeId,
            type: values.type,
            category: values.category,
            choices: values.choices,
            isFirst: values.isFirst,
            question: values.question,
            refId: [],
          });
        }

        return copyNodes;
      });
    },
    [handleSaveQuestion, setNodes]
  );

  const handleEditNode = useCallback(
    (node: Node) => {
      const selectedQuestion = currentQuestions.find(
        (question) => question.id === node.id
      );

      nodeForm.setFieldsValue({
        id: selectedQuestion?.id,
        question: selectedQuestion?.question,
        isFirst: selectedQuestion?.isFirst,
        type: selectedQuestion?.type,
        category: selectedQuestion?.category,
        choices: selectedQuestion?.choices ? selectedQuestion?.choices : [],
      });
      setNodeModal(true);
    },
    [nodeForm, currentQuestions]
  );

  const handleRemoveQuestion = useCallback(
    (id: string) => {
      handleDeleteQuestion(id);

      setCurrentQuestions((prev) =>
        prev.filter((question) => question.id !== id)
      );

      setNodes((prev) => prev.filter((node) => node.id !== id));
      setEdges((prev) =>
        prev.filter((edge) => edge.source !== id || edge.target !== id)
      );
    },
    [handleDeleteQuestion, setNodes, setEdges]
  );

  const handleEditEdge = useCallback(
    (edge: Edge) => {
      const selectedQuestion = currentQuestions.find(
        (question) => question.id === edge.source
      );

      if (selectedQuestion?.type !== "text") {
        edgeForm.setFieldsValue({
          id: edge.id,
          source: edge.source,
          target: edge.target,
          choice: edge.label,
          choices: selectedQuestion?.choices
            ? selectedQuestion?.choices
            : ["Yes", "No"],
        });
        setEdgeModal(true);
      }
    },
    [edgeForm, currentQuestions]
  );

  const handleSaveConnectQuestion = useCallback(
    async (values: any) => {
      const savedEdgeId = await handleConnectQuestion(values);

      setEdges((prev) => {
        const copyEdges = prev.slice();
        const currentEdgeIndex = copyEdges.findIndex(
          (edge) => edge.id === values.id
        );
        if (currentEdgeIndex !== -1) {
          copyEdges.splice(currentEdgeIndex, 1, {
            id: values.id,
            source: values.source,
            target: values.target,
            label: values.choice,
          });
        } else {
          copyEdges.push({
            id: savedEdgeId,
            source: values.source,
            target: values.target,
            label: values.choice,
          });
        }

        return copyEdges;
      });
    },
    [handleConnectQuestion, setEdges]
  );

  const handleRemoveQuestionConnection = useCallback(
    (id: string) => {
      handleDeleteConnectQuestion(id);

      setEdges((prev) => {
        const newEdges = prev.filter((edge) => edge.id !== id);
        return newEdges;
      });
    },
    [setEdges, handleDeleteConnectQuestion]
  );

  return (
    <>
      <div className="flex flex-col gap-[30px] mr-[104px] w-full h-full">
        <Breadcrumbs
          items={[
            { title: "Registration Company", href: ROUTES.DASHBOARD.ROOT },
            { title: "Question's Flow" },
          ]}
        />
        <div className="flex-1 flex flex-col gap-[42px] rounded-[10px] shadow bg-white p-[20px] h-full">
          <h3 className="text-dark font-termina text-[14px] font-normal leading-7">
            Review the question's flow.
          </h3>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodesTypes}
            onNodesChange={onNodesChange}
            onNodeDoubleClick={(event, node) => handleEditNode(node)}
            onEdgesChange={onEdgesChange}
            onEdgeDoubleClick={(event, edge) => handleEditEdge(edge)}
            onConnect={onConnect}
            fitView
            fitViewOptions={{
              nodes,
            }}
            isValidConnection={isValidConnection}
          >
            <Tooltip title="Add Question" placement="left">
              <Button
                type="default"
                onClick={() => {
                  nodeForm.setFieldsValue({
                    question: "",
                    type: "text",
                    category: "",
                    choices: [],
                  });
                  setNodeModal(true);
                }}
                className="absolute top-[10px] right-[10px] z-50 bg-white"
              >
                <FaPlus />
              </Button>
            </Tooltip>
            <Tooltip title="Reset Layout" placement="right">
              <Button
                type="default"
                onClick={() => handleMountGraph(currentQuestions)}
                className="absolute top-[10px] left-[10px] z-50 bg-white"
              >
                <MdRefresh />
              </Button>
            </Tooltip>
            <Controls />
            <Background gap={12} size={1} />
          </ReactFlow>
          <ModalNode
            form={nodeForm}
            onFinish={handleSubmitNode}
            onDelete={handleRemoveQuestion}
            open={nodeModal}
            setOpen={setNodeModal}
          />

          <ModalEdge
            form={edgeForm}
            onFinish={handleSaveConnectQuestion}
            onDelete={handleRemoveQuestionConnection}
            open={edgeModal}
            setOpen={setEdgeModal}
          />
        </div>
      </div>
    </>
  );
};

export const QuestionsGraph: React.FC = () => {
  return (
    <ReactFlowProvider>
      <Graph />
    </ReactFlowProvider>
  );
};
