import React, { useEffect, useMemo } from "react";

import { ISurvey } from "../../../domain/entities/Survey";

import { ColumnProps, List } from "../../components/organisms/List";
import { useSurvey } from "../../hooks/useSurvey";
import dayjs from "dayjs";

import { ROUTES } from "../../Router";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "../../components/atoms/Breadcrumb";
import { Button } from "../../components/atoms/Button";

const surveyColumns: ColumnProps<ISurvey>[] = [
  {
    label: "Date",
    key: "createdAt",
    className: "!justify-start whitespace-nowrap max-w-[90px]",
  },
  {
    label: "Company",
    key: "companyName",
    className: "text-center whitespace-nowrap flex-1",
  },
  {
    label: "Email",
    key: "email",
    className: "text-center whitespace-nowrap flex-1",
  },
  {
    label: "Status AI",
    key: "statusAI",
    className: "text-center whitespace-nowrap max-w-[90px]",
    customRender(item, className) {
      const status: { [key: string]: string } = {
        approved: "text-green-darkest bg-green-light",
        processing: "text-yellow-dark bg-yellow-light",
        pending: "text-purple-default bg-purple-light",
        rejected: "text-red-default bg-red-lightest",
      };

      return (
        <span
          className={`${className} ${
            status[item.statusAI]
          } font-poppins text-[12px] leading-normal font-[600] h-full flex items-center justify-center capitalize rounded-full`}
        >
          {item.statusAI}
        </span>
      );
    },
  },
  {
    label: "",
    key: "action",
    className: "flex justify-end max-w-[100px]",
    customRender(item) {
      const isDisabled =
        item.statusAI === "pending" || item.statusAI === "processing";
      return (
        <Link
          to={ROUTES.DASHBOARD.SURVEY.replace(":surveyId", item.id)}
          className={`${isDisabled && "cursor-not-allowed"}`}
        >
          <Button
            className={`-m-[5px] ml-0 rounded-full !text-[12px]`}
            disabled={isDisabled}
          >
            review
          </Button>
        </Link>
      );
    },
  },
];

export const Dashboard: React.FC = () => {
  const {
    loading,
    surveys,
    actions: { handleGetSurveys },
  } = useSurvey();

  useEffect(() => {
    handleGetSurveys();
  }, [handleGetSurveys]);

  const surveyDataSource = useMemo(
    () =>
      surveys.map((survey) => ({
        id: survey.id,
        email: survey.email,
        companyName: survey.companyName,
        createdAt: dayjs(survey.createdAt).format("MM/DD/YYYY"),
        statusAI: survey.statusAI,
      })),
    [surveys]
  );

  return (
    <div className="flex flex-col gap-[30px] mr-[104px] w-full h-full">
      <Breadcrumbs items={[{ title: "Registration Company" }]} />
      <div className="flex-1 flex flex-col gap-[42px] rounded-[10px] shadow bg-white p-[20px] h-full">
        <h3 className="text-dark font-termina text-[14px] font-normal leading-7">
          Review company data for affiliation.
        </h3>
        <List
          columns={surveyColumns}
          loading={loading}
          data={surveyDataSource}
          emptyMessage="We don't have surveys yet!"
        />
      </div>
    </div>
  );
};
