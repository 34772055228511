import React, { useEffect, useMemo, useRef, useState } from "react";

import { ReactComponent as ConfirmIcon } from "../assets/img/icons/confirm.svg";

import { ChatMessage } from "../components/atoms/ChatMessage";
import { Aside } from "../components/organisms/Aside";
import { useSurvey } from "../hooks/useSurvey";
import { useSelector } from "react-redux";
import { surveySelector } from "../../domain/ducks/surveyReducer";
import { Navigate } from "react-router";
import { ROUTES } from "../Router";
import { Button } from "../components/atoms/Button";

import "../styles/loading.scss";
import style from "../styles/_survey.module.scss";
import { Breadcrumbs } from "../components/atoms/Breadcrumb";
import { RadioGroup } from "../components/atoms/RadioGroup";
import { InputRef, Skeleton } from "antd";
import { useQuestions } from "../hooks/useQuestions";
import { Input } from "../components/atoms/Input";
import { QuestionType } from "../../domain/entities/Question";
import { Select } from "../components/atoms/Select";

export const Survey: React.FC = () => {
  const chatRef = useRef<HTMLDivElement>(null);

  const { email, companyName } = useSelector(surveySelector);
  const {
    wasFinishSurvey,
    actions: { handleSelectAnswer, handleSaveSurvey },
  } = useSurvey();

  const {
    loading,
    chatQuestions,
    chatCurrentChoices,
    isFinalQuestion,
    actions: { handleGetFirstQuestion },
  } = useQuestions();

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [chatQuestions, isFinalQuestion]);

  useEffect(() => {
    if (chatQuestions.length === 0) {
      handleGetFirstQuestion();
    }
  }, [handleGetFirstQuestion, chatQuestions]);

  if (!email || !companyName) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  return (
    <section className={`${style.survey} flex gap-[30px] h-full mr-[30px]`}>
      <Aside title={email} subtitle={companyName}>
        <div className="__blank" />
        <h1 className="text-gray-light font-termina text-[16px] leading-[46px] mb-[24px]">
          {wasFinishSurvey ? "Completation finished" : "Registration company"}
        </h1>
        <p className="text-gray-dark font-roboto text-[18px] font-normal leading-[22px]">
          {wasFinishSurvey
            ? `Congratulations! Your registration is now complete. Get ready for the next steps on this exciting journey. Keep an eye on your inbox for important updates and instructions. Feel free to explore our platform and discover all the opportunities that await you. Welcome aboard!`
            : `In the process of completing your company application, it is crucial to review and verify responses for accuracy.`}
        </p>
      </Aside>
      <div className="pt-[22px] pb-[33px] flex flex-col flex-1 gap-[36px] h-screen">
        <Breadcrumbs
          items={[{ title: "Registration Company" }, { title: "Form" }]}
          className="h-[25px]"
        />

        <div className="flex flex-col flex-1 h-[77vh]">
          {wasFinishSurvey ? (
            <div className="flex-1 flex flex-col items-center justify-center gap-[20px]">
              <span className="w-[60px] h-[60px] rounded-full bg-primary-default flex items-center justify-center">
                <ConfirmIcon />
              </span>
              <h2 className="text-black font-termina text-xl font-bold leading-5">
                Welcome aboard!
              </h2>
              <p className="text-gray-[#333] text-center font-termina text-[18px] font-[400] leading-5 w-[420px]">
                We're excited to have you on board, and we look forward to
                sharing valuable experiences together. Feel free to explore and
                make the most of your journey with us!
              </p>
            </div>
          ) : (
            <div className="flex-1 flex flex-col justify-end p-[12px] gap-[38px] bg-white h-full rounded-[10px]">
              <div
                ref={chatRef}
                className="flex flex-col gap-[20px] -mr-[30px] pr-[30px] overflow-y-auto"
              >
                {chatQuestions.length > 0 &&
                  chatQuestions.map((item, index) => (
                    <div key={index} className="flex flex-col gap-[14px]">
                      <ChatMessage
                        name={item.origin === "chat" ? "Roadie" : "Me"}
                        date={item.date}
                        side={item.origin === "chat" ? "left" : "right"}
                      >
                        {item.message}
                      </ChatMessage>
                    </div>
                  ))}

                {loading && (
                  <ChatMessage name="Roadie" side="left">
                    <span className="loading">
                      <span />
                      <span />
                      <span />
                    </span>
                  </ChatMessage>
                )}

                {isFinalQuestion && !loading && (
                  <div className="w-full flex items-center opacity-30 after:h-[1px] after:bg-gray-dark after:flex-1 before:h-[1px] before:bg-gray-dark before:flex-1">
                    <span className="text-[12px] px-[10px]">
                      The Survey Ended
                    </span>
                  </div>
                )}
              </div>

              {!isFinalQuestion && chatCurrentChoices && (
                <FormSection
                  loading={loading}
                  onSubmit={handleSelectAnswer}
                  type={chatCurrentChoices.type as QuestionType}
                  question={chatCurrentChoices.question}
                  choices={chatCurrentChoices.choices}
                />
              )}
            </div>
          )}
        </div>

        {!wasFinishSurvey && (
          <div className="flex items-center justify-end h-[56px]">
            <Button
              onClick={() => handleSaveSurvey()}
              disabled={!isFinalQuestion}
              className={`${
                !isFinalQuestion && "cursor-not-allowed opacity-30"
              }`}
            >
              Confirm
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

interface FormSectionProps {
  loading: boolean;
  type: QuestionType;
  choices: string[] | string | null;
  question: string;
  onSubmit: (value: string, question: string) => void;
}

const FormSection: React.FC<FormSectionProps> = ({
  loading,
  type,
  choices,
  question,
  onSubmit,
}) => {
  const ref = useRef<InputRef>(null);

  const [answer, setAnswer] = useState("");

  useEffect(() => {
    if (!loading && type === "text" && ref.current) {
      ref.current.focus();
    }
  }, [loading, type]);

  const checkOptions = useMemo(() => {
    const currentChoices =
      typeof choices === "string" ? JSON.parse(choices) : choices;

    return type !== "text" && currentChoices.length > 5 ? (
      <Select
        placeholder="Select an option"
        options={currentChoices.map((item: string) => ({
          label: item,
          value: item,
        }))}
        variant="borderless"
        onChange={(value) => setAnswer(value)}
      />
    ) : (
      <RadioGroup
        data={currentChoices}
        onChange={(e) => setAnswer(e.target.value)}
      />
    );
  }, [choices, type]);

  if (loading) {
    return (
      <div
        className={`flex items-center rounded-full border-2 border-solid border-gray-light p-[8px] w-full gap-[29px] ${
          loading && "cursor-not-allowed opacity-50 pointer-events-none"
        }`}
      >
        <div className="flex gap-[20px]">
          <Skeleton
            active
            className="w-[100px] h-[40px]"
            title={{
              className: "!h-[40px] !rounded-full",
            }}
            paragraph={false}
          />
          <Skeleton
            active
            className="w-[100px] h-[40px]"
            title={{
              className: "!h-[40px] !rounded-full",
            }}
            paragraph={false}
          />
          <Skeleton
            active
            className="w-[100px] h-[40px]"
            title={{
              className: "!h-[40px] !rounded-full",
            }}
            paragraph={false}
          />
          <Skeleton
            active
            className="w-[100px] h-[40px]"
            title={{
              className: "!h-[40px] !rounded-full",
            }}
            paragraph={false}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-[20px]">
      <div
        className={`flex items-center rounded-full border-2 border-solid border-gray-light p-[8px] w-full gap-[29px] ${
          loading && "cursor-not-allowed opacity-50 pointer-events-none"
        }`}
      >
        {type === "option" && checkOptions}

        {type === "boolean" && (
          <RadioGroup
            data={["Yes", "No"]}
            onChange={(e) => setAnswer(e.target.value)}
          />
        )}

        {type === "text" && (
          <Input ref={ref} onChange={(e) => setAnswer(e.target.value)} />
        )}

        <Button onClick={() => onSubmit(answer, question)} className="h-[34px]">
          Send
        </Button>
      </div>
    </div>
  );
};
