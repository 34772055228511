import axios, { AxiosInstance } from "axios";
import { EngageStore } from "../../domain/DomainLayer";
import { toast } from "react-toastify";
import { ROUTES } from "../../presentation/Router";
import { signOutThunk } from "../../domain/thunks/authThunk";

export let api: AxiosInstance;
export let ai: AxiosInstance;

export const initializeAxios = (store: EngageStore): void => {
  api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
  });

  api.interceptors.response.use(null, (error) => {
    if (
      (error.response.status === 403 || error.response.status === 401) &&
      !error.config.url.includes("auth/check")
    ) {
      toast(error.response.data.message, { type: "error" });

      store.dispatch(signOutThunk() as any);

      window.location.href = ROUTES.LOGIN;
    }

    return Promise.reject(error);
  });

  ai = axios.create({
    baseURL: process.env.REACT_APP_AI_URL,
  });
};
