import { Button, Form, FormInstance, Modal, Select } from "antd";
import React from "react";

interface ModalEdgeProps {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance<any>;
  onFinish: (values: {
    id?: string;
    source: string;
    target: string;
    choice: string;
  }) => void;
  onDelete?: (questionId: string) => void;
}

export const ModalEdge: React.FC<ModalEdgeProps> = ({
  open,
  setOpen,
  form,
  onFinish,
  onDelete,
}) => {
  return (
    <Modal
      centered
      open={open}
      footer={false}
      onCancel={() => {
        form.setFieldsValue({ source: "", target: "", choice: "" });
        setOpen && setOpen(false);
      }}
      wrapClassName="p-[20px]"
    >
      <Form
        className="p-[20px]"
        layout="vertical"
        form={form}
        onFinish={(values) => {
          onFinish(values);

          form.resetFields();
          setOpen && setOpen(false);
        }}
      >
        <p className="opacity-50 mb-[40px]">
          If you leave empty, the questions will be connected without a choice
        </p>

        <Form.Item name="id" noStyle hidden />
        <Form.Item name="source" noStyle hidden />
        <Form.Item name="target" noStyle hidden />

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues !== currentValues
          }
        >
          {({ getFieldValue }) => {
            const choices = getFieldValue("choices");

            return (
              <Form.Item name="choice" label="Choice" initialValue={""}>
                <Select
                  options={[
                    { value: "", label: "None" },
                    ...(choices
                      ? choices.map((option: string[]) => ({
                          value: option,
                          label: option,
                        }))
                      : []),
                  ]}
                />
              </Form.Item>
            );
          }}
        </Form.Item>

        <div className="flex items-center mt-[40px]">
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.id !== currentValues.id
            }
          >
            {({ getFieldValue }) => {
              const id = getFieldValue("id");
              return id ? (
                <Button
                  danger
                  onClick={() => {
                    onDelete && onDelete(id);
                    form.resetFields();
                    setOpen && setOpen(false);
                  }}
                >
                  Delete
                </Button>
              ) : null;
            }}
          </Form.Item>
          <div className="w-full gap-[10px] flex justify-end ">
            <Button type="text" onClick={() => form.resetFields()}>
              Cancel
            </Button>

            <Form.Item noStyle>
              <Button htmlType="submit">Save</Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
