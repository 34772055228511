import React from "react";
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Login } from "./pages/Login";
import { Schedule } from "./pages/Schedule";
import { Survey } from "./pages/Survey";
import { PrivateRoute } from "./components/PrivateRoute";
import { Dashboard, Survey as DashboardSurvey } from "./pages/Dashboard";
import { Chatbot } from "./pages/Chatbot";
import { QuestionsGraph } from "./pages/Dashboard/QuestionsGraph";

export const ROUTES = {
  ROOT: "/",

  LOGIN: "/login",

  SCHEDULE: "/schedule/:scheduleId",
  SURVEY: "/survey",

  CHATBOT: "/chatbot",

  // PRIVATE ROUTE
  DASHBOARD: {
    ROOT: "/dashboard",
    SURVEY: "/dashboard/survey/:surveyId",
    QUESTIONS_GRAPH: "/dashboard/questions",
  },
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={ROUTES.ROOT}>
      <Route path={ROUTES.ROOT} element={<Navigate to={ROUTES.LOGIN} />} />
      <Route path={ROUTES.LOGIN} element={<Login />} />

      <Route path={ROUTES.SURVEY} element={<Survey />} />

      <Route path={ROUTES.SCHEDULE} element={<Schedule />} />

      <Route path={ROUTES.CHATBOT} element={<Chatbot />} />

      <Route path={ROUTES.DASHBOARD.ROOT} element={<PrivateRoute />}>
        <Route path={ROUTES.DASHBOARD.ROOT} element={<Dashboard />} />
        <Route path={ROUTES.DASHBOARD.SURVEY} element={<DashboardSurvey />} />

        <Route
          path={ROUTES.DASHBOARD.QUESTIONS_GRAPH}
          element={<QuestionsGraph />}
        />
      </Route>
    </Route>
  )
);
