import { api } from ".";
import { IAvailability } from "../../domain/entities/Availability";

export async function checkScheduleLink(scheduleId: string): Promise<{
  email: string;
  companyName: string;
  availability: IAvailability;
}> {
  return (await api.get(`/schedule/${scheduleId}`)).data;
}

export async function saveSchedule(
  scheduleId: string,
  date: string
): Promise<void> {
  await api.post(`/schedule/${scheduleId}`, { meetDate: date });
}
