import { useCallback, useState } from "react";
import { ai } from "../../data/services";
import { toast } from "react-toastify";

interface UseChatbotProps {
  loading: boolean;
  chat: { origin: "user" | "chat"; message: string }[];
  actions: {
    handleSendMessage: (message: string) => void;
  };
}

export function useChatbot(): UseChatbotProps {
  const [chat, setChat] = useState<
    { origin: "user" | "chat"; message: string }[]
  >([]);
  const [loading, setLoading] = useState(false);

  const handleSendMessage = useCallback(async (message: string) => {
    try {
      setChat((prev) => [...prev, { origin: "user", message: message }]);
      setLoading(true);
      const response = await ai.post("/chatbot", { question: message });
      setChat((prev) => [
        ...prev,
        { origin: "chat", message: response.data.message },
      ]);
      setLoading(false);
    } catch (error) {
      toast("Error", { type: "error" });
    }
  }, []);

  return {
    loading,
    chat,
    actions: {
      handleSendMessage,
    },
  };
}
