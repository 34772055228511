import React, { Fragment, useEffect } from "react";
import { useSurvey } from "../../hooks/useSurvey";
import { useParams } from "react-router";

import dayjs from "dayjs";
import { Button } from "../../components/atoms/Button";
import { Breadcrumbs } from "../../components/atoms/Breadcrumb";
import { ROUTES } from "../../Router";
import { Skeleton } from "antd";
import { LOADING_DUMP } from "../../utils/constants";

export const Survey: React.FC = () => {
  const {
    loading,
    survey,
    actions: { handleGetSurveyById, handleChangeStatusSurvey },
  } = useSurvey();

  const params = useParams();

  useEffect(() => {
    if (params.surveyId) {
      handleGetSurveyById(params.surveyId);
    }
  }, [params.surveyId, handleGetSurveyById]);

  return (
    <div className="flex flex-col gap-[30px] w-full h-full">
      <Breadcrumbs
        items={[
          { title: "Registration Company", href: ROUTES.DASHBOARD.ROOT },
          { title: "Application review" },
        ]}
      />

      <div className="flex flex-col gap-[33px] flex-1 overflow-hidden bg-white shadow p-[20px] pb-[72px] rounded-[10px] h-full">
        <span className="text-gray-dark font-poppins text-[12px] leading-[141.667%]">
          Application review:
        </span>
        <div className="px-[20px] flex flex-col flex-1 gap-[22px] h-full box-border">
          <div className="flex justify-between items-center">
            <h2 className="text-black font-termina text-[22px] font-bold leading-6 capitalize">
              {loading ? (
                <Skeleton
                  active
                  title={{ className: "!h-[22px] !w-[100px]" }}
                  paragraph={false}
                />
              ) : (
                survey?.companyName
              )}
            </h2>
            <p className="text-gray-dark flex items-center gap-[5px] whitespace-nowrap font-roboto text-[12px] font-normal leading-4">
              <span className="font-[600]">Applied in: </span>
              {loading ? (
                <Skeleton
                  active
                  title={{ className: "!h-[18px] !w-[50px]" }}
                  paragraph={false}
                />
              ) : (
                dayjs(survey?.createdAt).format("DD MMMM")
              )}
            </p>
          </div>
          <div className="bg-white bg-opacity-80 overflow-y-auto flex-1 flex flex-col">
            {loading ? (
              <div className="flex flex-col gap-[5px]">
                {LOADING_DUMP.map((_, index) => (
                  <Skeleton
                    key={index}
                    title={{ className: "!h-[35px]" }}
                    paragraph={false}
                    active
                  />
                ))}
              </div>
            ) : (
              survey?.SurveyAnswers.map((answer) => (
                <Fragment key={answer.question}>
                  <span className="text-black flex items-center font-roboto text-[12px] font-[200] min-h-[35px] pl-[10px] border-gray-lightest border-[1px] border-l-[4px] border-l-gray-darker border-solid">
                    {answer.question}
                  </span>
                  <span className="text-gray-dark flex items-center font-poppins text-[12px] font-[600] min-h-[35px] pl-[10px] bg-gray-light border-gray-lightest border-[1px] border-l-[4px] border-l-primary-default border-solid">
                    {answer.answer}
                  </span>
                </Fragment>
              ))
            )}
          </div>
          {!loading && (
            <div className="flex justify-end items-center gap-[10px]">
              <Button
                type="button"
                variant="secondary"
                className="!text-[14px] h-[40px]"
                onClick={() =>
                  handleChangeStatusSurvey(survey?.id as string, "rejected")
                }
              >
                Reject
              </Button>
              <Button
                type="button"
                className="!text-[14px] h-[40px]"
                onClick={() =>
                  handleChangeStatusSurvey(survey?.id as string, "approved")
                }
              >
                Accept
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
