import {
  BreadcrumbProps as AntdBreadcrumbProps,
  Breadcrumb as AntdBreadcrumb,
} from "antd";
import React, { useMemo } from "react";

import { ReactComponent as ArrowLinedIcon } from "../../assets/img/icons/arrow-lined.svg";

interface BreadcrumbProps extends AntdBreadcrumbProps {}

export const Breadcrumbs: React.FC<BreadcrumbProps> = ({ items, ...rest }) => {
  const formattedItems = useMemo(() => {
    return items?.map((item, index) => ({
      ...item,
      className: `text-gray-darker font-montserrat text-[16px] leading-6 tracking-tighter ${
        index === items.length - 1 ? "font-bold" : "font-light"
      }`,
    }));
  }, [items]);

  return (
    <AntdBreadcrumb
      {...rest}
      items={formattedItems}
      separator={
        <div className=" h-full flex items-center">
          <ArrowLinedIcon
            className="text-gray-darker mx-[10px] font-bold"
            width={11}
            height={11}
          />
        </div>
      }
    />
  );
};
