import {
  ActionReducerMapBuilder,
  createAction,
  createReducer,
  PayloadAction,
  PayloadActionCreator,
  Reducer,
} from "@reduxjs/toolkit";
import { EngageState } from "../DomainLayer";
import { IQuestion, QuestionType } from "../entities/Question";
import { IMessage } from "../entities/Chat";
import {
  getFirstQuestionThunk,
  getNextQuestionThunk,
  getQuestionsThunk,
} from "../thunks/questionThunk";

export type QuestionActionsType = PayloadAction<null>;

export interface QuestionState {
  questions: IQuestion[];
  selectedQuestion?: IQuestion;
  isFinalQuestion: boolean;
  showedQuestion: (IMessage & { questionId: string })[];
  currentChoices?: {
    choices: string | string[] | null;
    type: QuestionType;
    question: string;
  };
  isLoading: boolean;
}

export const QUESTION_INITIAL_STATE: QuestionState = {
  questions: [],
  isFinalQuestion: false,
  showedQuestion: [],
  isLoading: false,
};

export const questionSelector = (state: EngageState): QuestionState =>
  state.question;

export const saveAnswerThunk: PayloadActionCreator<{
  answer: string;
  question: string;
}> = createAction("duck/question/saveAnswer");

export const nextQuestion: PayloadActionCreator<number> = createAction(
  "duck/question/nextQuestion"
);

function handlePendingThunk(state: QuestionState): QuestionState {
  return {
    ...state,
    isLoading: true,
  };
}

function handleRejectedThunk(state: QuestionState): QuestionState {
  return {
    ...state,
    isLoading: false,
  };
}

function handleGetQuestions(
  state: QuestionState,
  action: PayloadAction<IQuestion[]>
): QuestionState {
  return {
    ...state,
    questions: action.payload,
    isLoading: false,
  };
}

function handleNextQuestion(
  state: QuestionState,
  action: PayloadAction<{ question: IQuestion; isFinal: boolean }>
): QuestionState {
  const { question, isFinal } = action.payload;

  if (question) {
    return {
      ...state,
      isLoading: false,
      isFinalQuestion: isFinal,
      showedQuestion: [
        ...state.showedQuestion,
        {
          message: question.question,
          date: new Date().toISOString(),
          origin: "chat",
          questionId: question.id,
        },
      ],
      currentChoices: {
        choices: question.choices,
        type: question.type as QuestionType,
        question: question.id,
      },
    };
  }

  return {
    ...state,
    isFinalQuestion: isFinal,
    isLoading: false,
  };
}

function handleSaveAnswer(
  state: QuestionState,
  action: PayloadAction<{ answer: string; question: string }>
): QuestionState {
  const { answer, question } = action.payload;

  let newQuestions = state.showedQuestion.slice();

  newQuestions.push({
    message: answer,
    date: new Date().toISOString(),
    origin: "user",
    questionId: question,
  });

  return {
    ...state,
    showedQuestion: newQuestions,
  };
}

const builder = (builder: ActionReducerMapBuilder<QuestionState>) =>
  builder
    .addCase(getQuestionsThunk.pending.type, handlePendingThunk)
    .addCase(getQuestionsThunk.rejected.type, handleRejectedThunk)
    .addCase(getQuestionsThunk.fulfilled.type, handleGetQuestions)
    .addCase(getFirstQuestionThunk.pending.type, handlePendingThunk)
    .addCase(getFirstQuestionThunk.rejected.type, handleRejectedThunk)
    .addCase(getFirstQuestionThunk.fulfilled.type, handleNextQuestion)
    .addCase(getNextQuestionThunk.pending.type, handlePendingThunk)
    .addCase(getNextQuestionThunk.rejected.type, handleRejectedThunk)
    .addCase(getNextQuestionThunk.fulfilled.type, handleNextQuestion)
    .addCase(saveAnswerThunk.type, handleSaveAnswer);

export const questionReducer: Reducer<QuestionState, QuestionActionsType> =
  createReducer(QUESTION_INITIAL_STATE, builder);
