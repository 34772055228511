import { api } from ".";
import { IAnswer } from "../../domain/entities/SurveyAnswers";

export async function saveSurvey(payload: {
  email: string;
  companyName: string;
  answers: IAnswer[];
}) {
  await api.post("/survey", payload);
}

export async function getSurveys() {
  return (await api.get("/survey")).data;
}

export async function getSurveyById(surveyId: string) {
  return (await api.get(`/survey/${surveyId}`)).data;
}

export async function changeStatusSurvey(
  surveyId: string,
  status: "approved" | "rejected"
) {
  await api.patch(`/survey/${surveyId}`, { statusMentor: status });
}
