import {
  Button,
  Checkbox,
  Form,
  FormInstance,
  Input,
  Modal,
  Select,
} from "antd";
import React from "react";
import {
  DEFAULT_EMPTY_QUESTION,
  IQuestion,
  QUESTION_TYPE,
  QuestionCategory,
} from "../../../../domain/entities/Question";
import { FaPlus, FaTrashAlt } from "react-icons/fa";

interface ModalNodeProps {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance<any>;
  onFinish: (question: IQuestion) => void;
  onDelete?: (questionId: string) => void;
}

export const ModalNode: React.FC<ModalNodeProps> = ({
  open = false,
  setOpen,
  form,
  onFinish,
  onDelete,
}) => {
  const [currentForm] = Form.useForm(form);

  return (
    <Modal
      centered
      open={open}
      footer={false}
      onCancel={() => {
        setOpen && setOpen(false);
        form.resetFields();
      }}
    >
      <Form
        className="p-[20px]"
        layout="vertical"
        form={currentForm}
        initialValues={DEFAULT_EMPTY_QUESTION}
        onFinish={(values) => {
          onFinish(values);
          setOpen && setOpen(false);
          form.resetFields();
        }}
      >
        <Form.Item name="id" noStyle hidden />
        <Form.Item
          name="question"
          label="Question"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item valuePropName="checked" name="isFirst">
          <Checkbox>Is First Question ?</Checkbox>
        </Form.Item>
        <Form.Item
          name="category"
          label="Category"
          rules={[{ required: true }]}
        >
          <Select
            options={Object.entries(QuestionCategory).map(([key, value]) => ({
              label: value,
              value: key,
            }))}
          />
        </Form.Item>
        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
          <Select
            options={Object.entries(QUESTION_TYPE).map(([key, value]) => ({
              label: value,
              value: key,
            }))}
          />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.type !== currentValues.type
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("type") === "option" && (
              <Form.List name="choices">
                {(subFields, subOpt) => (
                  <div className="flex flex-col gap-[16px]">
                    <div className="flex items-center">
                      <span className="block flex-1">Choices</span>
                      <Button onClick={() => subOpt.add()}>
                        <FaPlus />
                      </Button>
                    </div>

                    {subFields.map((subField) => (
                      <div
                        key={subField.key}
                        className="flex items-center gap-[10px]"
                      >
                        <Form.Item className="flex-1 m-0" name={subField.name}>
                          <Input placeholder="Type the choice" />
                        </Form.Item>
                        <Button
                          onClick={() => {
                            subOpt.remove(subField.name);
                          }}
                          className="hover:!text-red-400 hover:!border-red-400"
                        >
                          <FaTrashAlt />
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </Form.List>
            )
          }
        </Form.Item>

        <div className="flex flex-1 items-center mt-[40px]">
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.id !== currentValues.id
            }
          >
            {({ getFieldValue }) => {
              const id = getFieldValue("id");
              return id ? (
                <Button
                  danger
                  onClick={() => {
                    onDelete && onDelete(id);

                    setOpen && setOpen(false);
                    form.resetFields();
                  }}
                >
                  Delete
                </Button>
              ) : null;
            }}
          </Form.Item>
          <div className="w-full gap-[10px] flex justify-end ">
            <Button
              type="text"
              onClick={() => {
                setOpen && setOpen(false);
                form.resetFields();
              }}
            >
              Cancel
            </Button>

            <Form.Item noStyle>
              <Button htmlType="submit">Save</Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
