import {
  configureStore,
  EnhancedStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { ReducersMapObject } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  SurveyActionsType,
  surveyReducer,
  SurveyState,
} from "./ducks/surveyReducer";
import {
  ScheduleActionsType,
  ScheduleState,
  scheduleReducer,
} from "./ducks/scheduleReducer";
import { AuthActionsType, AuthState, authReducer } from "./ducks/authReducer";
import {
  QuestionActionsType,
  QuestionState,
  questionReducer,
} from "./ducks/questionReducer";

export interface EngageState {
  readonly auth: AuthState;
  readonly survey: SurveyState;
  readonly schedule: ScheduleState;
  readonly question: QuestionState;
}

export type EngageActions = SurveyActionsType &
  ScheduleActionsType &
  AuthActionsType &
  QuestionActionsType;

export type EngageStore = EnhancedStore<EngageState, EngageActions>;
const rootReducer: ReducersMapObject<EngageState, EngageActions> = {
  auth: authReducer,
  survey: surveyReducer,
  schedule: scheduleReducer,
  question: questionReducer,
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const combinedReducers = combineReducers(rootReducer);

const appReducer = (state: any, action: any) => combinedReducers(state, action);

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
