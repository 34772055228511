import React, { useCallback } from "react";
import { Modal } from "antd";
import { useAvailability } from "../../hooks/useAvailability";

import { IoAddOutline, IoTrashOutline } from "react-icons/io5";
import { Button } from "../atoms/Button";
import {
  IAvailabilityDay,
  AvailabilityKey,
  WEEK_DAY,
} from "../../../domain/entities/Availability";
import { TimePickerRange } from "../atoms/TimeRangePicker";

interface AvailabilityModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AvailabilityModal: React.FC<AvailabilityModalProps> = ({
  open,
  setOpen,
}) => {
  const {
    availability,
    changeAvailability,
    actions: { handleUpdateAvailability },
  } = useAvailability();

  const handleRemoveAvailability = useCallback(
    (key: AvailabilityKey, index: number) => {
      const modifiedAvailability: IAvailabilityDay[] = [...availability[key]];

      modifiedAvailability.splice(index, 1);

      changeAvailability({ [key]: modifiedAvailability });
    },
    [availability, changeAvailability]
  );

  const handleChangeAvailability = useCallback(
    (key: AvailabilityKey, index: number, value: IAvailabilityDay) => {
      const newAvailability = [...availability[key]];

      newAvailability[index] = {
        start: value.start ?? null,
        end: value.end ?? null,
      };
      changeAvailability({ [key]: newAvailability });
    },
    [availability, changeAvailability]
  );

  return (
    <Modal
      centered
      closable={false}
      open={open}
      onOk={() => {
        setOpen(false);
        handleUpdateAvailability();
      }}
      onCancel={() => setOpen(false)}
      footer={null}
    >
      <div className="!flex !flex-col gap-[40px]">
        <h2 className="font-termina text-[22px]">Availability</h2>
        <div className="flex flex-col gap-[10px]">
          {Object.entries(availability).map(([key, value]: [any, any]) => (
            <div key={key} className="flex gap-[10px]">
              <span className="h-[32px] flex items-center w-[100px] font-termina text-[14px]">
                {WEEK_DAY[key as keyof typeof WEEK_DAY]}
              </span>
              <div className="flex-1 flex flex-col gap-[10px]">
                {value.length > 0 ? (
                  value.map((item: IAvailabilityDay, index: number) => (
                    <div
                      key={`${item.start}-${item.end}`}
                      className="flex items-center gap-[10px]"
                    >
                      <TimePickerRange
                        value={item}
                        onChange={(pickerValue) =>
                          handleChangeAvailability(key, index, pickerValue)
                        }
                      />
                      <button
                        onClick={() => handleRemoveAvailability(key, index)}
                      >
                        <IoTrashOutline size={16} />
                      </button>
                    </div>
                  ))
                ) : (
                  <span className="opacity-50 text-black font-termina text-[14px] h-[32px] flex items-center">
                    Unavailable
                  </span>
                )}
              </div>
              <button
                className="h-[32px]"
                onClick={() =>
                  changeAvailability({
                    [key]: [...value, { start: null, end: null }],
                  })
                }
              >
                <IoAddOutline size={18} />
              </button>
            </div>
          ))}
        </div>
        <div className="flex gap-[20px] justify-end items-center">
          <Button variant="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleUpdateAvailability}>Confirm</Button>
        </div>
      </div>
    </Modal>
  );
};
