import React from "react";

import { Select as SelectComponent, SelectProps } from "antd";

export const Select: React.FC<SelectProps> = ({ className, ...rest }) => {
  return (
    <SelectComponent
      {...rest}
      className={`flex-1 rounded-full h-[40px] ${className} `}
      popupClassName="rounded-[15px] mb-[10px]"
    />
  );
};
