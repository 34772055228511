import dayjs from "dayjs";
import React, { HTMLAttributes, useMemo } from "react";

interface ChatMessageProps extends HTMLAttributes<HTMLElement> {
  name: string;
  date?: string;
  side: "left" | "right";
}

export const ChatMessage: React.FC<ChatMessageProps> = ({
  name,
  date,
  side,
  children,
  className,
  ...rest
}) => {
  const chatSide = useMemo(
    () => ({
      right:
        "text-gray-dark font-poppins text-[12px] font-normal leading-5 bg-gray-light",
      left: "text-gray-darker font-termina text-[14px] font-[600] leading-6 bg-transparent",
    }),
    []
  );

  return (
    <div
      {...rest}
      className={`whitespace-pre-line flex flex-col w-fit  ${
        side === "right" ? "self-end" : ""
      } ${className ?? ""}`}
    >
      <span
        className={`text-gray-dark font-poppins text-[10px] leading-5 ${
          side === "right" && "text-end"
        }`}
      >
        {name}, {dayjs(date).format("HH:MM")}
      </span>
      <p
        className={`min-h-[40px] max-w-[630px] flex items-center px-[17px] py-[9px] rounded-[10px] ${chatSide[side]}`}
      >
        {children}
      </p>
    </div>
  );
};
