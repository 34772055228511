import {
  ActionReducerMapBuilder,
  createAction,
  createReducer,
  PayloadAction,
  PayloadActionCreator,
  Reducer,
} from "@reduxjs/toolkit";
import { EngageState } from "../DomainLayer";
import {
  checkAuthThunk,
  getUserInfoThunk,
  patchAvailabilityThunk,
  signOutThunk,
} from "../thunks/authThunk";
import { toast } from "react-toastify";
import { IUser } from "../entities/User";
import { IAvailability } from "../entities/Availability";

export type AuthActionsType = PayloadAction<null>;

export interface AuthState {
  isAuth: boolean;
  user?: IUser;
  isLoading: boolean;
}

export const AUTH_INITIAL_STATE: AuthState = {
  isAuth: false,
  isLoading: true,
};

export const changeToken: PayloadActionCreator<string> = createAction(
  "duck/auth/changeToken"
);

export const authSelector = (state: EngageState): AuthState => state.auth;

function handlePendingThunk(state: AuthState): AuthState {
  return {
    ...state,
    isLoading: true,
  };
}

function handleRejectedThunk(
  state: AuthState,
  action: PayloadAction<string>
): AuthState {
  toast(action.payload, { type: "error" });

  return {
    ...state,
    isLoading: false,
  };
}

function handleSignOut(
  state: AuthState,
  action: PayloadAction<void>
): AuthState {
  return {
    ...state,
    isAuth: false,
    user: undefined,
    isLoading: false,
  };
}

function handleChangeToken(
  state: AuthState,
  action: PayloadAction<boolean>
): AuthState {
  return {
    ...state,
    isAuth: action.payload,
    isLoading: false,
  };
}

function handleGetUserInfo(
  state: AuthState,
  action: PayloadAction<IUser>
): AuthState {
  return {
    ...state,
    user: action.payload,
    isAuth: true,
    isLoading: false,
  };
}

function handlePatchAvailability(
  state: AuthState,
  action: PayloadAction<IAvailability>
): AuthState {
  return {
    ...state,
    user: {
      ...state.user,
      availability: action.payload,
    },
    isAuth: true,
    isLoading: false,
  };
}

const builder = (builder: ActionReducerMapBuilder<AuthState>) =>
  builder
    .addCase(getUserInfoThunk.fulfilled.type, handleGetUserInfo)
    .addCase(getUserInfoThunk.pending.type, handlePendingThunk)
    .addCase(getUserInfoThunk.rejected.type, handleRejectedThunk)
    .addCase(checkAuthThunk.fulfilled.type, handleGetUserInfo)
    .addCase(checkAuthThunk.pending.type, handlePendingThunk)
    .addCase(checkAuthThunk.rejected.type, handleSignOut)
    .addCase(signOutThunk.fulfilled.type, handleSignOut)
    .addCase(signOutThunk.pending.type, handlePendingThunk)
    .addCase(signOutThunk.rejected.type, handleRejectedThunk)
    .addCase(patchAvailabilityThunk.fulfilled.type, handlePatchAvailability)
    .addCase(patchAvailabilityThunk.pending.type, handlePendingThunk)
    .addCase(patchAvailabilityThunk.rejected.type, handleRejectedThunk)
    .addCase(changeToken.type, handleChangeToken);

export const authReducer: Reducer<AuthState, AuthActionsType> = createReducer(
  AUTH_INITIAL_STATE,
  builder
);
