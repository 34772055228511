import React, { useCallback, useState } from "react";

import { ReactComponent as LoginImg } from "../assets/img/login.svg";
import { ReactComponent as GraphicIcon } from "../assets/img/icons/graphic.svg";

import { ReactComponent as RevRoadLogo } from "../assets/img/revroad-logo.svg";
import { ReactComponent as GoogleIcon } from "../assets/img/icons/google-white.svg";
import { useAuth } from "../hooks/useAuth";
import { useGoogleLogin } from "@react-oauth/google";
import { Navigate } from "react-router";
import { ROUTES } from "../Router";
import { Button } from "../components/atoms/Button";
import { Input } from "../components/atoms/Input";

export const Login: React.FC = () => {
  const {
    isAuth,
    actions: { handleSignIn, handleSocialSignIn },
  } = useAuth();

  const googleAuth = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      handleSocialSignIn(tokenResponse.code);
    },
    flow: "auth-code",
  });

  const [formType, setFormType] = useState<string>("ppc");

  const handleToggleForm = useCallback(() => {
    setFormType((prev) => (prev === "ppc" ? "mentor" : "ppc"));
  }, []);

  if (isAuth) {
    return <Navigate to={ROUTES.DASHBOARD.ROOT} />;
  }

  return (
    <div className="w-full h-screen flex flex-col bg-opacity-80 relative px-[75px] py-[25px] ">
      <RevRoadLogo />
      <section className="flex flex-1">
        <div className="flex flex-col p-[26px]">
          <div className="__blank" />
          <div className="flex flex-col gap-[17px] w-[600px]">
            <h1 className="text-gray-darker font-termina text-[48px] font-semibold leading-14">
              Unleash Growth with Roadie CI
            </h1>
            <p className="text-gray-darker font-termina text-[18px] font-normal leading-7">
              Your strategic ally for business expansion.
            </p>
          </div>

          <form
            className="flex flex-col gap-[8px] mt-[80px] w-[290px]"
            onSubmit={handleSignIn}
          >
            {formType === "ppc" ? (
              <>
                <Input placeholder="Company Name" name="companyName" required />
                <Input type="email" placeholder="Email" name="email" required />
                <Button type="submit">Continue</Button>
              </>
            ) : (
              <Button
                onClick={() => googleAuth()}
                type="button"
                className="flex gap-[15px]"
              >
                Log in with Google
                <GoogleIcon />
              </Button>
            )}
            <span
              className="text-primary-darkest text-center font-roboto text-[12px] font-light leading-6 cursor-pointer"
              onClick={() => handleToggleForm()}
            >
              Are you a {formType === "ppc" ? "mentor" : "portfolio company"}?{" "}
              <span className="font-bold">Click here</span>
            </span>
          </form>
          <div className="__blank"></div>
        </div>
        <div className="__blank relative flex items-center justify-center">
          <LoginImg />
          <GraphicIcon className="absolute bottom-20" />
        </div>
      </section>
    </div>
  );
};
