import React from "react";

import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";

dayjs.extend(isToday);

export const CellRender: React.FC<{ day: dayjs.Dayjs; selected: boolean }> = ({
  day,
  selected,
}) => {
  return (
    <div
      className={`
        text-center font-termina text-base font-normal leading-5 rounded-full relative flex items-center justify-center max-w-[40px] h-[40px] mx-auto 
        ${day.isBefore() && "opacity-50"}
        ${day.isToday() && "border border-primary-default !opacity-100"} 
        ${selected && "bg-primary-default text-white"}
      `}
    >
      {dayjs(day).date()}
    </div>
  );
};
