import { Dispatch } from "redux";
import { EngageState } from "../DomainLayer";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  checkAuth,
  getUserInfo,
  signOut,
} from "../../data/services/google/auth";
import { IUser } from "../entities/User";
import { IAvailability } from "../entities/Availability";
import { patchAvailability } from "../../data/services/availability";
import { toast } from "react-toastify";

export interface ThunkApi {
  dispatch: Dispatch;
  state: EngageState;
  rejectValue: string;
}

export const AuthActions = {
  GET_USER_INFO: "thunk/auth/getUserInfoThunk",
  CHECK_AUTH: "thunk/auth/checkAuthThunk",
  SIGN_OUT: "thunk/auth/signOutThunk",
  PATCH_AVAILABILITY: "thunk/auth/patchAvailabilityThunk",
};

export const getUserInfoThunk = createAsyncThunk<IUser, string, ThunkApi>(
  AuthActions.GET_USER_INFO,
  async (payload, thunkAPI) => {
    try {
      const { userInfo } = await getUserInfo(payload);
      return userInfo;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const signOutThunk = createAsyncThunk<void, void, ThunkApi>(
  AuthActions.SIGN_OUT,
  async (payload, thunkAPI) => {
    try {
      await signOut();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const checkAuthThunk = createAsyncThunk<IUser, void, ThunkApi>(
  AuthActions.CHECK_AUTH,
  async (payload, thunkAPI) => {
    try {
      const { userInfo } = await checkAuth();
      return userInfo;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const patchAvailabilityThunk = createAsyncThunk<
  IAvailability,
  IAvailability,
  ThunkApi
>(AuthActions.PATCH_AVAILABILITY, async (payload, thunkAPI) => {
  try {
    await patchAvailability(payload);

    toast("Availability updated successfully!", { type: "success" });

    return payload;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});
