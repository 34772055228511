import React, { HTMLAttributes, useMemo, useState } from "react";
import { SimpleHeader } from "../molecules/SimpleHeader";
import { useAuth } from "../../hooks/useAuth";

import { PiClock, PiSignOutLight } from "react-icons/pi";
import { Badge, Menu, MenuProps, Popover } from "antd";
import { IoSettingsOutline } from "react-icons/io5";
import { Button } from "../atoms/Button";
import { AvailabilityModal } from "../molecules/AvailabilityModal";

type MenuItem = Required<MenuProps>["items"][number];

interface AsideProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  subtitle?: string;
  avatar?: string;
}

export const Aside: React.FC<AsideProps> = ({
  children,
  title,
  subtitle,
  avatar,
}) => {
  const {
    user,
    isAuth,
    actions: { handleSignOut },
  } = useAuth();

  const [openModal, setOpenModal] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  const items = useMemo<MenuItem[]>(
    () => [
      {
        label: "Availability",
        key: "1",
        icon: (
          <Badge dot={!user?.availability}>
            <PiClock />
          </Badge>
        ),
        className: "!gap-[5px] border-none !text-black",
        onClick: () => {
          setOpenModal(true);
          setPopupOpen(false);
        },
      },
      {
        label: "Signout",
        key: "2",
        icon: <PiSignOutLight />,
        className: "!gap-[5px] border-none !text-black",
        onClick: () => handleSignOut(),
      },
    ],
    [handleSignOut, user?.availability]
  );

  const renderMenu = useMemo(
    () => <Menu items={items} theme="light" className="!border-none" />,
    [items]
  );

  return (
    <aside className="flex-1 flex flex-col max-w-[375px] min-w-[375px] w-full bg-black relative rounded-tr-[20px] rounded-br-[20px] py-[25px] px-[40px]">
      <SimpleHeader />
      <div className="flex-1 flex flex-col gap-[5px]">{children}</div>

      {title && subtitle ? (
        <footer className="mt-[155px] flex items-center gap-[22px] justify-between">
          {avatar && (
            <img
              src={avatar}
              alt={title}
              className="w-[40px] h-[40px] rounded-full"
            />
          )}
          <div className="flex flex-col">
            <p className="text-gray-dark font-roboto text-[18px] leading-[22px]">
              {title || "Roadie Mentor"}
            </p>
            <span className="text-primary-default font-roboto text-[12px] font-light leading-[22px]">
              {subtitle || "CEO Founder"}
            </span>
          </div>
          {isAuth && (
            <Popover
              content={renderMenu}
              arrow={false}
              placement="topRight"
              trigger="click"
              open={popupOpen}
              onOpenChange={setPopupOpen}
            >
              <Badge dot={!user?.availability} size="default">
                <Button variant="tertiary">
                  <IoSettingsOutline size={18} />
                </Button>
              </Badge>
            </Popover>
          )}
        </footer>
      ) : (
        <div className="mt-[155px]" />
      )}

      <AvailabilityModal open={openModal} setOpen={setOpenModal} />
    </aside>
  );
};
