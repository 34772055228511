export interface IQuestion {
  id: string;
  type: string;
  question: string;
  category: string;
  isFirst: boolean;
  choices: string | string[] | null;
  depth?: number;
  refId: {
    edgeId: string;
    id: string;
    choice: string | null;
  }[];
}

export interface QuestionConnection {
  id?: string;
  source: string;
  target: string;
  choice: string | null;
}

export enum QUESTION_TYPE {
  text = "text",
  boolean = "boolean",
  option = "option",
}

export type QuestionType = keyof typeof QUESTION_TYPE;

export enum QuestionCategory {
  personal_information = "Personal Information",
  company_information = "Company Information",
  historical_information = "Historical Information",
  monetary_information = "Monetary Information",
  uploaded_information = "Uploaded Information",
  goals = "Goals",
}

export const DEFAULT_EMPTY_QUESTION: IQuestion = {
  id: "",
  type: "",
  isFirst: false,
  question: "",
  category: "",
  choices: [],
  refId: [],
};
