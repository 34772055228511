import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../Router";
import { Aside } from "./organisms/Aside";
import { Menu, MenuProps } from "antd";

import { useAuth } from "../hooks/useAuth";

import { ReactComponent as CopyIcon } from "../assets/img/icons/menu/copy.svg";
import { TiFlowChildren } from "react-icons/ti";

type MenuItem = Required<MenuProps>["items"][number];

export const PrivateRoute: React.FC = () => {
  const {
    isAuth,
    user,
    actions: { handleCheckAuth },
  } = useAuth();

  const [current, setCurrent] = useState<string>("1");

  const navigate = useNavigate();

  const location = useLocation();

  const items: MenuItem[] = useMemo(
    () => [
      {
        label: "Registration Company",
        key: "1",
        icon: <CopyIcon className="text-white" />,
        className: "border-r-primary-default !gap-[5px] !-mr-[40px]",
        title: ROUTES.DASHBOARD.ROOT,
        onClick: () => {
          navigate(ROUTES.DASHBOARD.ROOT);
        },
      },

      {
        label: "Questions Flow",
        key: "2",
        icon: <TiFlowChildren color="text-white" />,
        className: "border-r-primary-default !gap-[5px] !-mr-[40px]",
        title: ROUTES.DASHBOARD.QUESTIONS_GRAPH,
        onClick: () => {
          navigate(ROUTES.DASHBOARD.QUESTIONS_GRAPH);
        },
      },
    ],
    [navigate]
  );

  useEffect(() => {
    if (!isAuth) {
      handleCheckAuth();
    }
  }, [handleCheckAuth, isAuth]);

  useEffect(() => {
    const currentItem = items.find(
      (item: any) => item.title === location.pathname
    );
    setCurrent(String(currentItem?.key) || "1");
  }, [items, location.pathname]);

  if (!isAuth) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  return (
    <div className="flex h-full gap-[30px]">
      <Aside
        title={user?.name || "RoadieCI"}
        subtitle={user?.email || "noreply@roadie.ci"}
        avatar={user?.picture}
      >
        <div className="flex-1 mt-[85px] flex flex-col">
          <Menu
            className="font-termina"
            defaultSelectedKeys={["1"]}
            onClick={(e) => setCurrent(e.key)}
            selectedKeys={[current]}
            theme="dark"
            items={items}
          />
        </div>
        <div className="__blank" />
      </Aside>
      <section className="pt-[22px] pb-[114px] pr-[50px] flex-1">
        <Outlet />
      </section>
    </div>
  );
};
