import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { IQuestion, QuestionType } from "../../domain/entities/Question";
import { AppDispatch } from "../../domain/DomainLayer";
import {
  getFirstQuestionThunk,
  getQuestionsThunk,
} from "../../domain/thunks/questionThunk";
import { questionSelector } from "../../domain/ducks/questionReducer";
import { IMessage } from "../../domain/entities/Chat";
import {
  deleteQuestion,
  deleteQuestionConnection,
  saveQuestion,
  saveQuestionConnection,
} from "../../data/services/question";

type UseQuestionProps = {
  loading: boolean;
  questions: IQuestion[];
  chatQuestions: IMessage[];
  chatCurrentChoices?: {
    choices: string | string[] | null;
    type: QuestionType;
    question: string;
  };
  isFinalQuestion: boolean;
  actions: {
    handleGetQuestions: () => void;
    handleGetFirstQuestion: () => void;
    handleSaveQuestion: (question: IQuestion) => Promise<string>;
    handleDeleteQuestion: (questionId: string) => Promise<void>;
    handleConnectQuestion: (values: {
      id?: string;
      source: string;
      target: string;
      choice: string | null;
    }) => Promise<string>;
    handleDeleteConnectQuestion: (questionId: string) => void;
  };
};

export function useQuestions(): UseQuestionProps {
  const {
    isLoading,
    questions,
    showedQuestion,
    currentChoices,
    isFinalQuestion,
  } = useSelector(questionSelector);

  const dispatch = useDispatch<AppDispatch>();

  const handleSaveQuestion = useCallback(async (question: IQuestion) => {
    return await saveQuestion(question);
  }, []);

  const handleConnectQuestion = useCallback(
    async (values: {
      id?: string;
      source: string;
      target: string;
      choice: string | null;
    }) => {
      return await saveQuestionConnection(values);
    },
    []
  );

  const handleDeleteQuestion = useCallback(async (questionId: string) => {
    await deleteQuestion(questionId);
  }, []);

  const handleDeleteConnectQuestion = useCallback((questionId: string) => {
    deleteQuestionConnection(questionId);
  }, []);

  const handleGetQuestions = useCallback(() => {
    dispatch(getQuestionsThunk());
  }, [dispatch]);

  const handleGetFirstQuestion = useCallback(() => {
    dispatch(getFirstQuestionThunk());
  }, [dispatch]);

  return {
    loading: isLoading,
    questions,
    chatQuestions: showedQuestion,
    chatCurrentChoices: currentChoices,
    isFinalQuestion,
    actions: {
      handleGetQuestions,
      handleGetFirstQuestion,
      handleSaveQuestion,
      handleDeleteQuestion,
      handleConnectQuestion,
      handleDeleteConnectQuestion,
    },
  };
}
