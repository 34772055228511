import { api } from "..";
import { IUser } from "../../../domain/entities/User";

export async function getUserInfo(code: string): Promise<{ userInfo: IUser }> {
  return (await api.post("/auth", { code })).data;
}

export async function signOut(): Promise<void> {
  await api.get("/auth/signout");
}

export async function checkAuth(): Promise<{ userInfo: IUser }> {
  return (await api.get("/auth/check")).data;
}
