import dayjs, { ManipulateType } from "dayjs";

export function getTimes({
  start,
  end,
  step = "minutes",
  stepValue = 15,
}: {
  start: string;
  end: string;
  step?: ManipulateType;
  stepValue?: number;
}) {
  const startTime = dayjs(start, "HH:mm");
  const endTime = dayjs(end, "HH:mm");
  const availableTimes = [];
  for (
    let i = startTime;
    i.isBefore(endTime) || i.isSame(endTime);
    i = i.add(stepValue, step)
  ) {
    availableTimes.push(i.format("HH:mm"));
  }
  return availableTimes;
}
