import {
  ActionReducerMapBuilder,
  createReducer,
  PayloadAction,
  Reducer,
} from "@reduxjs/toolkit";
import { EngageState } from "../DomainLayer";
import { checkScheduleLinkThunk } from "../thunks/scheduleThunk";

export type ScheduleActionsType = PayloadAction<null>;

export interface ScheduleState {
  availability: {
    dates: [];
    times: [];
  };
  isLoading: boolean;
}

export const SCHEDULE_INITIAL_STATE: ScheduleState = {
  availability: {
    dates: [],
    times: [],
  },
  isLoading: true,
};

export const scheduleSelector = (state: EngageState): ScheduleState =>
  state.schedule;

function handlePendingThunk(state: ScheduleState): ScheduleState {
  return {
    ...state,
    isLoading: true,
  };
}

function handleRejectedThunk(state: ScheduleState): ScheduleState {
  return {
    ...state,
    isLoading: false,
  };
}

function handleCheckScheduleLink(state: ScheduleState): ScheduleState {
  return {
    ...state,
    isLoading: false,
  };
}

const builder = (builder: ActionReducerMapBuilder<ScheduleState>) =>
  builder
    .addCase(checkScheduleLinkThunk.pending.type, handlePendingThunk)
    .addCase(checkScheduleLinkThunk.rejected.type, handleRejectedThunk)
    .addCase(checkScheduleLinkThunk.fulfilled.type, handleCheckScheduleLink);

export const scheduleReducer: Reducer<ScheduleState, ScheduleActionsType> =
  createReducer(SCHEDULE_INITIAL_STATE, builder);
