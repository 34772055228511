import { Dispatch } from "redux";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { EngageState } from "../DomainLayer";
import { IQuestion } from "../entities/Question";
import {
  getFirstQuestion,
  getNextQuestion,
  getQuestions,
} from "../../data/services/question";

export interface ThunkApi {
  dispatch: Dispatch;
  state: EngageState;
  rejectValue: string;
}

export const QuestionActions = {
  GET_QUESTIONS: "thunk/questions/getQuestions",
  GET_FIRST_QUESTION: "thunk/questions/getFirstQuestion",
  SAVE_QUESTION: "thunk/questions/saveQuestion",
  DELETE_QUESTION: "thunk/questions/deleteQuestion",
  SAVE_QUESTION_CONNECTION: "thunk/questions/saveQuestionConnection",
  DELETE_QUESTION_CONNECTION: "thunk/questions/saveQuestionConnection",
  GET_NEXT_QUESTION: "thunk/questions/getNextQuestion",
  SAVE_ANSWER: "thunk/questions/saveAnswer",
};

export const getFirstQuestionThunk = createAsyncThunk<
  { question: IQuestion; isFinal: boolean },
  void,
  ThunkApi
>(QuestionActions.GET_FIRST_QUESTION, async (payload, thunkAPI) => {
  try {
    const question = await getFirstQuestion();

    return { question, isFinal: false };
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getQuestionsThunk = createAsyncThunk<IQuestion[], void, ThunkApi>(
  QuestionActions.GET_QUESTIONS,
  async (payload, thunkAPI) => {
    try {
      const { questions } = await getQuestions();

      return questions;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getNextQuestionThunk = createAsyncThunk<
  { question: IQuestion; isFinal: boolean },
  { id: string; choice: string | null },
  ThunkApi
>(QuestionActions.GET_NEXT_QUESTION, async (payload, thunkAPI) => {
  try {
    const nextQuestion = await getNextQuestion(payload);

    return nextQuestion;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});
