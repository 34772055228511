import React, { forwardRef } from "react";

import { Input as InputComponent, InputProps, InputRef } from "antd";

export const Input = forwardRef<InputRef, InputProps>(
  ({ className, ...rest }, ref) => {
    return (
      <InputComponent
        {...rest}
        ref={ref}
        className={`rounded-full bg-gray-lighter px-[20px] py-[11px] outline-none border-none placeholder:text-gray-dark text-gray-darker font-termina text-[16px] font-light leading-5 ${
          className ?? ""
        }`}
      />
    );
  }
);
