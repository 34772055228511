import {
  ActionReducerMapBuilder,
  createAction,
  createReducer,
  PayloadAction,
  PayloadActionCreator,
  Reducer,
} from "@reduxjs/toolkit";
import { EngageState } from "../DomainLayer";
import { IQuestion } from "../entities/Question";
import {
  getSurveyByIdThunk,
  getSurveysThunk,
  saveSurveyThunk,
} from "../thunks/surveyThunk";
import { ISurvey } from "../entities/Survey";
import { IMessage } from "../entities/Chat";
import { IAvailability } from "../entities/Availability";

export type SurveyActionsType = PayloadAction<null>;

export interface SurveyState {
  email?: string;
  companyName?: string;
  availability?: IAvailability;
  questions: IQuestion[];
  showedQuestion: (IMessage & { questionId: string })[];
  currentChoices?: {
    choices: string | string[] | null;
    question: string;
  };
  isFinalQuestion: boolean;
  wasFinishSurvey: boolean;
  surveys: ISurvey[];
  survey?: ISurvey;
  isLoading: boolean;
}

export const SURVEY_INITIAL_STATE: SurveyState = {
  questions: [],
  showedQuestion: [],
  isFinalQuestion: false,
  wasFinishSurvey: false,
  surveys: [],
  isLoading: true,
};

export const surveySelector = (state: EngageState): SurveyState => state.survey;

export const updateUserInfoThunk: PayloadActionCreator<{
  email: string;
  companyName: string;
}> = createAction("duck/survey/updateUserInfo");

export const changeLoading: PayloadActionCreator<boolean> = createAction(
  "duck/survey/changeLoading"
);

function handlePendingThunk(state: SurveyState): SurveyState {
  return {
    ...state,
    isLoading: true,
  };
}

function handleRejectedThunk(state: SurveyState): SurveyState {
  return {
    ...state,
    isLoading: false,
  };
}

function handleUpdateUserInfo(
  state: SurveyState,
  action: PayloadAction<{
    email: string;
    companyName: string;
    availability: IAvailability;
  }>
): SurveyState {
  return {
    ...state,
    email: action.payload.email,
    companyName: action.payload.companyName,
    availability: action.payload.availability,
  };
}

function handleChangeLoading(
  state: SurveyState,
  action: PayloadAction<boolean>
): SurveyState {
  return {
    ...state,
    isLoading: action.payload,
  };
}
function handleSaveSurvey(
  state: SurveyState,
  action: PayloadAction<void>
): SurveyState {
  return {
    ...state,
    wasFinishSurvey: true,
  };
}

function handleGetSurveys(
  state: SurveyState,
  action: PayloadAction<ISurvey[]>
): SurveyState {
  return {
    ...state,
    surveys: action.payload,
    isLoading: false,
  };
}
function handleGetSurveyById(
  state: SurveyState,
  action: PayloadAction<ISurvey>
): SurveyState {
  return {
    ...state,
    survey: action.payload,
    isLoading: false,
  };
}

const builder = (builder: ActionReducerMapBuilder<SurveyState>) =>
  builder
    .addCase(updateUserInfoThunk.type, handleUpdateUserInfo)
    .addCase(changeLoading.type, handleChangeLoading)
    .addCase(saveSurveyThunk.pending.type, handlePendingThunk)
    .addCase(saveSurveyThunk.rejected.type, handleRejectedThunk)
    .addCase(saveSurveyThunk.fulfilled.type, handleSaveSurvey)
    .addCase(getSurveysThunk.pending.type, handlePendingThunk)
    .addCase(getSurveysThunk.rejected.type, handleRejectedThunk)
    .addCase(getSurveysThunk.fulfilled.type, handleGetSurveys)
    .addCase(getSurveyByIdThunk.pending.type, handlePendingThunk)
    .addCase(getSurveyByIdThunk.rejected.type, handleRejectedThunk)
    .addCase(getSurveyByIdThunk.fulfilled.type, handleGetSurveyById);

export const surveyReducer: Reducer<SurveyState, SurveyActionsType> =
  createReducer(SURVEY_INITIAL_STATE, builder);
