import { Skeleton } from "antd";
import React, { CSSProperties, useMemo } from "react";
import { LOADING_DUMP } from "../../utils/constants";

export type ColumnProps<T = any> = {
  label: string;
  key: string;
  className?: string;
  customRender?: (
    item: T,
    className?: string,
    style?: CSSProperties
  ) => JSX.Element;
};

type TableProps = {
  columns: ColumnProps[];
  data: { [key: string]: string | undefined; className?: string }[];
  loading: boolean;
  emptyMessage: string;
};

export const List: React.FC<TableProps> = ({
  columns,
  data,
  loading,
  emptyMessage,
}) => {
  const columnWidth = useMemo(() => 100 / columns.length, [columns]);

  return (
    <div className="table-auto border-collapse w-full h-full overflow-y-auto">
      <div className="flex gap-[20px] px-[26px] sticky h-[40px] top-0 bg-white z-[1]">
        {columns.map((col) => (
          <span
            key={col.key}
            data-key={col.key}
            className={`flex items-center justify-center text-gray-dark font-poppins text-[12px] font-[700] leading-[163.418%] ${
              col.className ?? ""
            }`}
            style={{ width: `${columnWidth}%` }}
          >
            {col.label}
          </span>
        ))}
      </div>

      <div className="flex flex-col gap-[8px] bg-gray-lightest p-[11px] rounded-[20px]">
        {loading ? (
          LOADING_DUMP.map((_, index) => (
            <div
              key={index}
              className="rounded-full bg-white bg-opacity-80 backdrop-blur-2xl px-[15px] py-[10px] flex gap-[20px]"
            >
              {columns.map((col) => (
                <Skeleton
                  key={col.key}
                  active
                  paragraph={false}
                  className={`flex items-center justify-center text-gray-dark font-poppins text-[12px] leading-[180%] overflow-hidden text-ellipsis ${
                    col.className ?? ""
                  } `}
                  style={{ width: `${columnWidth}%` }}
                />
              ))}
            </div>
          ))
        ) : data.length === 0 ? (
          <div className="text-center font-termina text-[18px] font-normal leading-[40px]">
            <span>{emptyMessage}</span>
          </div>
        ) : (
          data.map((item, index) => (
            <div
              key={index}
              className="rounded-full bg-white bg-opacity-80 backdrop-blur-2xl pl-[15px] pr-[10px] py-[10px] flex gap-[20px]"
            >
              {columns.map((col) =>
                col.customRender ? (
                  <span
                    key={col.key}
                    className={`${col.className}`}
                    style={{ width: `${columnWidth}%` }}
                  >
                    {col.customRender(item, `${col.className}`)}
                  </span>
                ) : (
                  <span
                    key={col.key}
                    className={`flex items-center justify-center text-gray-dark font-poppins text-[12px] leading-[180%] overflow-hidden text-ellipsis ${
                      col.className ?? ""
                    } `}
                    style={{ width: `${columnWidth}%` }}
                  >
                    {item[col.key]}
                  </span>
                )
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};
