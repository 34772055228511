import { Dispatch } from "redux";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { EngageState } from "../DomainLayer";
import { checkScheduleLink, saveSchedule } from "../../data/services/schedule";
import { toast } from "react-toastify";
import { ROUTES } from "../../presentation/Router";
import { updateUserInfoThunk } from "../ducks/surveyReducer";
import { createBrowserHistory } from "history";
export interface ThunkApi {
  dispatch: Dispatch;
  state: EngageState;
  rejectValue: string;
}

export const ScheduleActions = {
  CHECK_LINK: "thunk/schedule/checkLink",
  SAVE_SCHEDULE: "thunk/schedule/saveSchedule",
};

export const checkScheduleLinkThunk = createAsyncThunk<void, string, ThunkApi>(
  ScheduleActions.CHECK_LINK,
  async (payload, thunkAPI) => {
    try {
      const userInfo = await checkScheduleLink(payload);

      thunkAPI.dispatch(updateUserInfoThunk(userInfo));
    } catch (error: any) {
      const history = createBrowserHistory();
      toast(error.response.data.message, {
        type: "error",
      });
      history.replace(ROUTES.LOGIN);

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const saveScheduleThunk = createAsyncThunk<
  void,
  { scheduleId: string; date: string },
  ThunkApi
>(ScheduleActions.SAVE_SCHEDULE, async (payload, thunkAPI) => {
  try {
    return await saveSchedule(payload.scheduleId, payload.date);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});
