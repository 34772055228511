import { api } from ".";
import { IQuestion, QuestionConnection } from "../../domain/entities/Question";

export async function getQuestions() {
  return (await api.get("/question")).data;
}

export async function getFirstQuestion() {
  return (await api.get("/question/first")).data;
}

export async function saveQuestion(payload: IQuestion) {
  return (await api.post("/question", payload)).data;
}

export async function deleteQuestion(payload: string) {
  await api.delete(`/question/${payload}`);
}

export async function saveQuestionConnection(payload: QuestionConnection) {
  return (await api.post("/question/connection", payload)).data;
}

export async function deleteQuestionConnection(payload: string) {
  await api.delete(`/question/connection/${payload}`);
}

export async function getNextQuestion(payload: {
  id: string;
  choice: string | null;
}) {
  return (await api.get(`/question/next/${payload.id}/${payload.choice}`)).data;
}
