import { FormEvent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateUserInfoThunk } from "../../domain/ducks/surveyReducer";
import { useNavigate } from "react-router";
import { ROUTES } from "../Router";
import {
  checkAuthThunk,
  getUserInfoThunk,
  signOutThunk,
} from "../../domain/thunks/authThunk";
import { AppDispatch } from "../../domain/DomainLayer";
import { authSelector } from "../../domain/ducks/authReducer";
import { IUser } from "../../domain/entities/User";

type UseAuthProps = {
  isAuth: boolean;
  user?: IUser;
  actions: {
    handleSignIn: (event: FormEvent<HTMLFormElement>) => void;
    handleSocialSignIn: (token: string) => void;
    handleSignOut: () => void;
    handleCheckAuth: () => void;
  };
};

export function useAuth(): UseAuthProps {
  const { isAuth, user } = useSelector(authSelector);
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const handleSignIn = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { email, companyName } = event.target as any;

      if (!email || !companyName) {
        toast("Email and Company Name are required!", {
          type: "error",
        });
        return;
      }

      await dispatch(
        updateUserInfoThunk({
          email: email.value,
          companyName: companyName.value,
        })
      );

      navigate(ROUTES.SURVEY);
    },
    [dispatch, navigate]
  );

  const handleSocialSignIn = useCallback(
    async (token: string) => {
      if (token) {
        dispatch(getUserInfoThunk(token));

        navigate(ROUTES.DASHBOARD.ROOT);
      }

      navigate(ROUTES.LOGIN);
    },
    [navigate, dispatch]
  );

  const handleSignOut = useCallback(() => {
    dispatch(signOutThunk());
  }, [dispatch]);

  const handleCheckAuth = useCallback(() => {
    dispatch(checkAuthThunk());
  }, [dispatch]);

  return {
    isAuth,
    user,
    actions: {
      handleSignIn,
      handleSocialSignIn,
      handleSignOut,
      handleCheckAuth,
    },
  };
}
