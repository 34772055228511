import React from "react";

import { ReactComponent as RevRoadLogo } from "../../assets/img/revroad-logo-white.svg";

export const SimpleHeader: React.FC = () => {
  return (
    <header className="flex gap-[13px] w-full items-center">
      <RevRoadLogo width={90} height={20} />
    </header>
  );
};
