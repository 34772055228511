import React, { ButtonHTMLAttributes, useMemo } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "tertiary";
}

export const Button: React.FC<ButtonProps> = ({
  variant = "primary",
  children,
  ...rest
}) => {
  const style = useMemo(
    () => ({
      primary: "bg-primary-default text-white",
      secondary: "bg-transparent text-primary-default",
      tertiary: "bg-black text-white border-none",
    }),
    []
  );

  return (
    <button
      {...rest}
      className={`${style[variant]} font-termina whitespace-nowrap text-[16px] font-medium leading-5 rounded-full border border-solid border-primary-default p-4 flex items-center gap-10 h-[40px] justify-center flex-shrink-0 disabled:opacity-50 disabled:cursor-not-allowed ${rest.className}`}
    >
      {children}
    </button>
  );
};
