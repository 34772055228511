import React from "react";
import { Handle, NodeProps, Position } from "reactflow";

export const NodeGraph: React.FC<NodeProps> = ({ data }) => {
  return (
    <div className="bg-white p-[20px] rounded shadow border max-w-[200px] text-center">
      <Handle position={Position.Left} type="target" />
      <span>{data.label}</span>
      <Handle position={Position.Right} type="source" />
    </div>
  );
};
