import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Persistor } from "redux-persist/es/types";
import { EngageStore } from "../domain/DomainLayer";
import { router } from "./Router";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { RouterProvider } from "react-router-dom";

export type MainProps = {
  store: EngageStore;
  persistor: Persistor;
};

export const Main: React.FC<MainProps> = (props: MainProps) => {
  const { store, persistor } = props;
  return (
    <>
      <ToastContainer />

      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_CLIENT_ID as string}
          >
            <main className="flex h-screen w-screen items-center justify-center bg-white overflow-hidden">
              <div className="w-[1392px] h-[516px] bg-primary-default fixed -rotate-[21.954deg] top-[642px] -left-[311px] flex-shrink-0 rounded-full blur-200" />
              <div className="z-[2] w-full h-full bg-gray-light bg-opacity-80 backdrop-blur-2xl">
                <RouterProvider router={router} />
              </div>
            </main>
          </GoogleOAuthProvider>
        </PersistGate>
      </Provider>
    </>
  );
};
