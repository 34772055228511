import { useDispatch, useSelector } from "react-redux";
import { surveySelector } from "../../domain/ducks/surveyReducer";
import { useCallback } from "react";
import { AppDispatch } from "../../domain/DomainLayer";
import {
  changeStatusSurveyThunk,
  getSurveyByIdThunk,
  getSurveysThunk,
  saveSurveyThunk,
} from "../../domain/thunks/surveyThunk";
import { ISurvey } from "../../domain/entities/Survey";
import { IAnswer } from "../../domain/entities/SurveyAnswers";
import { useNavigate } from "react-router";
import { ROUTES } from "../Router";
import {
  questionSelector,
  saveAnswerThunk,
} from "../../domain/ducks/questionReducer";
import { getNextQuestionThunk } from "../../domain/thunks/questionThunk";

type UseSurveyProps = {
  loading: boolean;
  wasFinishSurvey: boolean;
  surveys: ISurvey[];
  survey?: ISurvey;
  actions: {
    handleSelectAnswer: (value: string, question: string) => void;
    handleSaveSurvey: () => void;
    handleGetSurveys: () => void;
    handleGetSurveyById: (surveyId: string) => void;
    handleChangeStatusSurvey: (
      surveyId: string,
      status: "approved" | "rejected"
    ) => void;
  };
};

export function useSurvey(): UseSurveyProps {
  const { isLoading, wasFinishSurvey, surveys, survey } =
    useSelector(surveySelector);

  const { showedQuestion } = useSelector(questionSelector);

  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const handleSelectAnswer = useCallback(
    async (value: string, question: string) => {
      await dispatch(
        saveAnswerThunk({
          answer: value,
          question: question,
        })
      );

      await dispatch(
        getNextQuestionThunk({ id: question, choice: value as string | null })
      );
    },
    [dispatch]
  );

  const handleSaveSurvey = useCallback(() => {
    let mountedAnswers: IAnswer[] = [];

    for (let index = 0; index < showedQuestion.length; index += 2) {
      mountedAnswers.push({
        question: showedQuestion[index].message,
        answer: showedQuestion[index + 1].message,
      });
    }

    dispatch(saveSurveyThunk(mountedAnswers));
  }, [dispatch, showedQuestion]);

  const handleGetSurveys = useCallback(() => {
    dispatch(getSurveysThunk());
  }, [dispatch]);

  const handleGetSurveyById = useCallback(
    (surveyId: string) => {
      dispatch(getSurveyByIdThunk(surveyId));
    },
    [dispatch]
  );

  const handleChangeStatusSurvey = useCallback(
    (surveyId: string, status: "approved" | "rejected") => {
      dispatch(changeStatusSurveyThunk({ surveyId, status }));
      navigate(ROUTES.DASHBOARD.ROOT);
    },
    [dispatch, navigate]
  );

  return {
    loading: isLoading,
    wasFinishSurvey,
    surveys,
    survey,
    actions: {
      handleSelectAnswer,
      handleSaveSurvey,
      handleGetSurveys,
      handleGetSurveyById,
      handleChangeStatusSurvey,
    },
  };
}
